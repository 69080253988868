<template>
    <div style="background-color: #eee;">
        <!-- pop up demande offre -->
        <v-dialog
            v-model="ajouterVenteElecModal"
            persistent
            max-width="70%" 
            >
            <v-card style="overflow: hidden;">
                 <v-toolbar
                    :color="$colors[0]"
                    dark>
                    <span class="text-h6 text-white">  Ajouter une vente {{venteTypeEnergie}}</span>
                </v-toolbar>
                <v-card-text class="my-6">
                    <ajouterVenteElecComposent :dataVente="dataPourVente" :societe="societe" :typeVente="venteTypeEnergie" :pourcentage="pourcentage" :listCompteur="listeCompteurVente" @closeDialog="ajouterVenteElecModalClose">
                    </ajouterVenteElecComposent>
                </v-card-text>
            </v-card>
        </v-dialog>
        <!-- <lettreDeRisiliation v-if="openResiliationModal" :societe="societe" :listCompteur="listCompteur" @closeResiliationModal="openResiliationModal=false"/> -->
        <!-- <MandatResiliation v-if="openMandatModal" :societe="societe" :listCompteur="listCompteur" @closeResiliationModal="openMandatModal=false"/> -->
        <!-- pop up demande offre -->
        <v-dialog
            v-model="dialogDemandeOffre"
            persistent
            max-width="90%" 
            >
            <v-card style="overflow: hidden;">
                <v-toolbar
                    class="black--text"
                    :color="$colors[0]"
                    dark>
                    <span class="text-h6 text-white">Demander une nouvelle offre {{typeDeOffre}}</span>
                </v-toolbar>
                <v-card-text class="mt-8">
                    <v-form ref="formNouveauOffre">
                        <v-row>
                            <v-col class="pb-0" cols='12' sm="10" md="6" lg="6" xl="6">
                                <v-text-field type="date" v-model="nouveauOffre.dateDebut" label="Date Debut" outlined/>
                            </v-col>
                            <v-col  class="pb-0" cols='12' sm="10" md="6" lg="6" xl="6">
                                <v-select
                                    v-if="typeDeOffre=='GAZ'"
                                    required
                                    outlined
                                    v-model="profilGaz"
                                    :items="['P011', 'P012', 'P013', 'P014', 'P015', 'P016', 'P017', 'P018', 'P019']"
                                    label="Profil du compteur"
                                    :rules="[v => !!v || 'Champ obligatoire!']"
                                ></v-select>
                            </v-col>
                            <v-col cols='12' sm="10" md="6" lg="6" xl="6">
                                <v-select
                                    :items="lesCompteurPossible"
                                    :rules="[v => !!v || 'Champ obligatoire!']"
                                    v-model="nouveauOffre.numCompteur"
                                    label="Compteur"
                                    outlined
                                ></v-select>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="px-0 mx-4" cols='6' sm="2" md="2" lg="2" xl="2" v-for="(item,index) in listFournisseur" :key="index">
                                <v-row justify="start">
                                    <v-checkbox
                                        v-model="nouveauOffre.lesFournisseurPossible"
                                        :rules="[v => v?.length>0 || 'Champ obligatoire!']"
                                        :label="item"
                                        color="secondary"
                                        :value="item"
                                        hide-details
                                    ></v-checkbox>
                                </v-row>
                            </v-col>
                        </v-row>
                       <div class="d-flex justify-content-end"> 
                            <v-btn
                            text
                            color="error"
                            class="mt-8 mr-4"
                            @click="fermerChoisirOffre"
                            >
                            Fermer
                            </v-btn>
                        <v-btn
                            color="blue darken-4"
                            class="mt-8 mr-4 text-white"
                            @click="ajouterNouveauOffre"
                            >
                            Valider
                            </v-btn>
                        </div>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-dialog>
         <v-row> 
            <loadingComponent @closeDialog="dialog = $event" v-if="loading" :loadingProps="loading" :dialogProps="dialog"/>
        </v-row>
        <section style="background-color: #eee;" v-if="societe!= null"> 
            <div class="container-fluid py-5" style="height: 100%;">
                <div class="d-flex flex-row">
                    <div class="col-lg-4 pb-0 px-2">
                        <div class="card mb-0">
                        <div class="card-body text-center">
                            <h5 class="my-3">{{societe.Raison}}</h5>
                            <img src="../../assets/companies-icon.png" alt="avatar"
                            class="rounded-circle img-fluid" style="width: 150px;">
                            <p class="text-muted mb-1">{{societe.siret}}</p>
                            <small class="text-muted mb-4">{{societe.AdressePostal}}</small>
                            <div class="d-flex justify-content-center mb-2 mt-2">
                                <v-btn :color="$colors[0]" v-if="urlStatus!=''">
                                    <a style="text-decoration:none" :href="urlStatus" target="_blank"><span class="white--text">EXTRAIT PAPPERS<v-icon dense>mdi-download</v-icon></span></a>
                                </v-btn>
                            </div>
                        </div>
                        </div>
                        <div class="card mb-4 mb-lg-0 mt-2">
                            <div class="card-body p-0">
                                <ul class="list-group list-group-flush rounded-3">
                                    <li @click="afficherInfoPersonnelle" class="btn list-group-item d-flex align-items-center p-3">
                                        <v-icon :color='$colors[0]'>mdi-account-outline</v-icon>
                                        <p class="mb-0 ml-5">Information du client</p>
                                    </li>
                                    <li @click="DemandeDeCotation" class="btn list-group-item d-flex align-items-center p-3">
                                        <v-icon :color='$colors[0]'>mdi-clipboard-text-clock-outline</v-icon>
                                        <p class="mb-0 ml-5">Historique de cotation</p>
                                    </li>
                                   

                                    <li @click="getAllVenteProBySociete" class="btn list-group-item d-flex align-items-center p-3">
                                        <v-icon :color='$colors[0]'>mdi-clipboard-list-outline</v-icon>
                                        <p class="mb-0 ml-5">Liste des ventes</p>
                                    </li>
                                     <!-- comment data historique profil-societe -->
                                    <li @click="getAllVenteBySocieteCompte" class="btn list-group-item d-flex align-items-center p-3">
                                        <v-icon :color='$colors[0]'>mdi-clipboard-list-outline</v-icon>
                                        <p class="mb-0 ml-5">Liste des Offres</p>
                                    </li>
                                    <li @click="GenerationPDF" class="btn list-group-item d-flex align-items-center p-3">
                                        <v-icon :color='$colors[0]'>mdi-download-outline</v-icon>
                                        <p class="mb-0 ml-5">Génération Document(s)</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="card mb-4 mb-lg-0 mt-2">
                            <div class="card-body p-0">
                                <ul class="list-group list-group-flush rounded-3">
                                    <li class="list-group-item d-flex align-items-center p-3">
                                        <div>
                                            <v-card-title class="justify-start">
                                                <v-avatar class="mr-2" size="36" rounded>
                                                <img
                                                    alt="credit safe score"
                                                    src="../../assets/images/logos/logo-credit-safe.png"
                                                />
                                                </v-avatar>
                                                creditsafe
                                            </v-card-title>

                                            <v-card-text class="d-flex flex-row text-left text-body-1  pr-6"
                                                ><span class="pr-4">Score :</span>
                                                <div v-if='!isNaN(societe.scoreCreditSafe)'>
                                                    
                                                    <span class="ml-2 font-weight-black">{{
                                                    societe.scoreCreditSafe
                                                    }}</span>
                                                    / 100
                                                </div>
                                                <div v-else>{{societe.scoreCreditSafe}}</div>
                                            </v-card-text>
                                        </div>
                                    </li>
                                    <li class="list-group-item d-flex align-items-center p-3">
                                        <div>
                                            <v-card-title class="justify-start">
                                                <v-avatar class="mr-2" width="66px" height='30px' rounded>
                                                <img
                                                    alt="credit safe score"
                                                    src="../../assets/logoElliPro.png"
                                                />
                                                </v-avatar>
                                                <p class="mb-0 mt-1">Elli Pro</p>
                                            </v-card-title>

                                            <v-card-text class=" text-left text-body-1 pr-6"
                                                > <div >
                                                    <span>Score :</span>
                                                    <span class="ml-2 font-weight-black">
                                                    {{societe.scoreEllipro}}
                                                    </span> / 10
                                                </div>
                                            </v-card-text>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-8 pl-lg-0 px-2 pr-lg-0" v-if="vueAfficher=='client'">
                        <div class="card mb-2">
                            <div class="card-body">
                                <p class="mb-8"><b><span style="color:#1E6CA4" class="font-italic me-1">Informations</span></b> personnelles de client</p>
                                    <div class="d-flex justify-content-end mb-4">    
                                        <div v-if='editMode==false'>
                                            <v-btn :color="$colors[0]" class="text-white" @click="activeEdit"><v-icon>mdi-pencil-outline</v-icon></v-btn>
                                        </div>
                                        <div v-if='editMode==true'>
                                            <v-btn :color="$colors[0]" class="text-white mr-4" @click="cancelEdit"><v-icon>mdi-pencil-off-outline</v-icon></v-btn>
                                            <v-btn :color="$colors[0]" class="text-white" @click="saveChange"><v-icon>mdi-check-all</v-icon></v-btn>
                                        </div>
                                    </div>
                                <div class="row">
                                    <div class="col-sm-4">
                                        <p class="mb-0">Nom</p>
                                    </div>
                                    <div class="col-sm-8">
                                        <p class="text-muted mb-0" v-if='editMode==false'> {{societe.nom}} </p>
                                        <input type="text " v-if='editMode==true'
                                                class="form-control form-control-sm"
                                                v-model="societe.nom"
                                            />
                                    </div>
                                </div>
                                <hr>
                                <div class="row">
                                    <div class="col-sm-4">
                                        <p class="mb-0">Prénom</p>
                                    </div>
                                    <div class="col-sm-8">
                                        <p class="text-muted mb-0" v-if='editMode==false'>{{societe.prenom}}</p>
                                        <input type="text " v-if='editMode==true'
                                                class="form-control form-control-sm"
                                                v-model="societe.prenom"
                                            />
                                    </div>
                                </div>
                                <hr>
                                <div class="row">
                                    <div class="col-sm-4">
                                        <p class="mb-0">Email</p>
                                    </div>
                                    <div class="col-sm-8">
                                        <p class="text-muted mb-0" v-if='editMode==false'> {{societe.AdresseEmail}}</p>
                                        <input type="text " v-if='editMode==true'
                                                class="form-control form-control-sm"
                                                v-model="societe.AdresseEmail"
                                            />
                                    </div>
                                </div>
                                <hr>
                                <div class="row">
                                    <div class="col-sm-4">
                                        <p class="mb-0">Numéro de téléphone</p>
                                    </div>
                                    <div class="col-sm-8">
                                        <p class="text-muted mb-0" v-if='editMode==false'>{{societe.numTele}}</p>
                                        <input type="text " v-if='editMode==true'
                                                class="form-control form-control-sm"
                                                v-model="societe.numTele"
                                            />
                                    </div>
                                </div>
                                <hr>
                                <div class="row">
                                    <div class="col-sm-4">
                                        <p class="mb-0">Fonction</p>
                                    </div>
                                    <div class="col-sm-8">
                                        <p class="text-muted mb-0" v-if='editMode==false'>{{societe.fonction}}</p>
                                        <input type="text " v-if='editMode==true'
                                                class="form-control form-control-sm"
                                                v-model="societe.fonction"
                                            />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                        <div class="col-md-6 pb-0 pr-lg-0 px-4">
                            <div class="card mb-md-0" style="height:100%">
                                <div class="card-body">
                                    <p class="mb-4"><b><span style="color:#1E6CA4" class="font-italic me-1">Informations</span></b> Societe</p>
                                    
                                    <div class="row">
                                        <div class="col-5">
                                            <small class="mb-0">Raison</small>
                                        </div>
                                        <div class="col-7">
                                            <small class="text-muted mb-0"><em> {{societe.Raison}}</em></small>
                                        </div>
                                    </div>
                                    <hr>
                                    <div class="row">
                                        <div class="col-5">
                                            <small class="mb-0">Siren</small>
                                        </div>
                                        <div class="col-7">
                                            <small class="text-muted mb-0"><em> {{societe.siren}}</em></small>
                                        </div>
                                    </div>
                                    <hr>
                                    <div class="row">
                                        <div class="col-5">
                                            <small class="mb-0">Siret</small>
                                        </div>
                                        <div class="col-7">
                                            <small class="text-muted mb-0"><em> {{societe.siret}}</em></small>
                                        </div>
                                    </div>
                                    <hr>
                                    <div class="row">
                                        <div class="col-5">
                                            <small class="mb-0">Adresse Complete</small>
                                        </div>
                                        <div class="col-7">
                                            <small class="text-muted mb-0"><em> {{societe.AdressePostal}}</em></small>
                                        </div>
                                    </div>
                                    <hr>
                                    <div class="row">
                                        <div class="col-5">
                                            <small class="mb-0">Code Postale</small>
                                        </div>
                                        <div class="col-7">
                                            <small class="text-muted mb-0"><em> {{societe.codePostal}}</em></small>
                                        </div>
                                    </div>
                                    <hr>
                                    <div class="row">
                                        <div class="col-5">
                                            <small class="mb-0">Commune</small>
                                        </div>
                                        <div class="col-7">
                                            <small class="text-muted mb-0"><em> {{societe.commune}}</em></small>
                                        </div>
                                    </div>
                                    <hr>
                                    <div class="row">
                                        <div class="col-5">
                                            <small class="mb-0">Voie</small>
                                        </div>
                                        <div class="col-7">
                                            <small class="text-muted mb-0"><em> {{societe.Voie}}</em></small>
                                        </div>
                                    </div>
                                    <hr>
                                    
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 pl-lg-2 pl-4 pb-0 pl-sm-0">
                            <div style="height:100%" class="card mb-md-0">
                                <div class="card-body">
                                    <p class="mb-4"><span style="color:#1E6CA4" class="font-italic me-1"><b>Informations</b></span> Societe</p>
                                    
                                    <div class="row">
                                        <div class="col-5">
                                            <small class="mb-0">Capitaux propres</small>
                                        </div>
                                        <div class="col-7">
                                            <small class="text-muted mb-0"><em> {{isNaN(societe.capi)  ? societe.capi : Number(societe.capi).toLocaleString('fr-FR')+" €" }}</em></small>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-5">
                                            <small class="mb-0">Code Naf</small>
                                        </div>
                                        <div class="col-7">
                                            <small class="text-muted mb-0"><em> {{societe.codeNaf}}</em></small>
                                        </div>
                                    </div>
                                    <hr>
                                    <div class="row">
                                        <div class="col-5">
                                            <small class="mb-0">Code insee</small>
                                        </div>
                                        <div class="col-7">
                                            <small class="text-muted mb-0"><em> {{societe.codeInsee}}</em></small>
                                        </div>
                                    </div>
                                    <hr>
                                    <div class="row">
                                        <div class="col-5">
                                            <small class="mb-0">Capital social</small>
                                        </div>
                                        <div class="col-7">
                                            <small class="text-muted mb-0"><em> {{societe.capitalSocial}}</em></small>
                                        </div>
                                    </div>
                                    <hr>
                                    <div class="row">
                                        <div class="col-5">
                                            <small class="mb-0">Status</small>
                                        </div>
                                        <div class="col-7">
                                            <small class="text-muted mb-0"><em> {{societe.statusSociete}}</em></small>
                                        </div>
                                    </div>
                                    <hr>
                                    <div class="row">
                                        <div class="col-5">
                                            <small class="mb-0">Type</small>
                                        </div>
                                        <div class="col-7">
                                            <small class="text-muted mb-0"><em> {{societe.typeEtablissement}}</em></small>
                                        </div>
                                    </div>
                                    <hr>
                                    <div class="row">
                                        <div class="col-5">
                                            <small class="mb-0">n° RCS</small>
                                        </div>
                                        <div class="col-7 ">
                                            <small class="text-muted mb-0"><em> {{societe.RCS}}</em></small>
                                        </div>
                                    </div>
                                    <hr>
                                    <div class="row">
                                        <div class="col-5">
                                            <small class="mb-0">n° TVA</small>
                                        </div>
                                        <div class="col-7">
                                            <small class="text-muted mb-0"><em> {{societe.TVA}}</em></small>
                                        </div>
                                    </div>
                                    <hr>
                                    
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    
                    <div class="col-lg-8 pl-lg-0 pl-2 pr-2" v-if="vueAfficher=='historiqueDeCotation'">
                        <div class="card mb-3">
                            <div class="card-body pb-9">
                                <p class="mb-8"><b><span style="color:#1E6CA4" class="font-italic me-1">Les demandes de cotations </span></b>associées à l'entreprise</p>
                                 <!-- <div class="d-flex justify-content-left mb-2 mt-2">
                                    <v-row>
                                        <v-col cols="3" md="3" sm="3" xl="3">
                                            <v-btn :color="$colors[0]" @click="openResiliation">
                                                <span class="white--text font-weight-bold">Lettre de résiliation<v-icon class="ml-1" dense>mdi-download</v-icon></span>
                                            </v-btn>
                                        </v-col>
                                        <v-col cols="3" md="3" sm="3" xl="3">
                                            <v-btn :color="$colors[0]" @click="openMandat">
                                                <span class="white--text font-weight-bold">Mandat de résiliation<v-icon class="ml-1" dense>mdi-download</v-icon></span>
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </div> -->
                                 <b-table class="text-center" :busy="loadingTableCotation" fixed show-empty bordered responsive :fields="fieldDemandeDeCotation"  hover :items="demandeDeCotationItems"
                                    :per-page="perPageDC"
                                    :current-page="currentPageDC"
                                    id="demandeDeCotation">
                                    <template #empty="scope">
                                        <noDataTable/>
                                    </template>
                                    <template #table-busy>
                                                    <div style="color:#1E6CA4" class="text-center my-2">
                                                        <b-spinner class="align-middle"></b-spinner>
                                                        <strong class="ml-2">Chargement...</strong>
                                                    </div>
                                    </template>
                                    <template #cell(date)="data">
                                        {{ data.item.date}}
                                    </template>
                                    <template #cell(typeDemande)="data">
                                        {{ data.item.typeDemande}}
                                    </template>                               
                                    <template #cell(numCompteur)="data">
                                        <div style="max-width:200px">
                                                    
                                            <small>{{spliteList(data.item.numCompteur)[0]}}</small>
                                            <v-icon
                                                v-if="spliteListSlice(data.item.numCompteur)?.length>0"
                                                data-toggle="collapse" :data-target="'#multiCollapseCompteur'" aria-expanded="false" :aria-controls="'multiCollapseCompteur'"
                                                right
                                                >
                                                mdi-menu-down
                                            </v-icon>
                                            <div class="collapse multi-collapse" :id="'multiCollapseCompteur'">
                                                <div >
                                                    <small v-for="c in spliteListSlice(data.item.numCompteur)">
                                                        {{c}}
                                                    </small>    
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                    <template #cell(Echeance)="data">
                                        {{ data.item.Echeance}}
                                    </template>
                                    <template #cell(finContrat)="data">
                                        {{ data.item.finContrat}}
                                    </template>
                                    <template #cell(statut)="data">
                                    
                                        <small v-if="data.item.valide == '2'"><v-icon dense class="pr-1" style="color: red;">mdi-close-circle</v-icon><b>refusée</b></small>
                                        <small v-if="data.item.valide == '1'"><v-icon dense class="pr-1" style="color:#00E676;">mdi-check-circle</v-icon><b>acceptée</b></small>
                                        <small v-if="data.item.valide == '0'"><v-icon dense class="pr-1" style="color: gray;">mdi-timer-refresh-outline</v-icon><b>en attente</b></small>

                                    </template>     
                                    <template #cell(lienFournisseur)="data">
                                        <v-btn icon @click="choisirOffre(data.item.id, data.item.typeDemande)">
                                            <v-icon dense :color="$colors[0]" >mdi-tag</v-icon>
                                        </v-btn>
                                    </template>                                                                                                                                                           
                                </b-table>
                                        
                                <b-pagination
                                    v-if="demandeDeCotationItems?.length > 0"
                                    v-model="currentPageDC"
                                    :total-rows="demandeDeCotationItems?.length"
                                    :per-page="perPageDC"
                                    aria-controls="demandeDeCotation"
                                    >
                                </b-pagination>
                            </div>
                            <div class="card-body" v-if="lesOffresDemander?.length>0">
                                <p class="mb-8"><b><span class="text-primary font-italic me-1">Les Offres </span>Externe demander</b></p>
                                <table v-if="vueAfficher=='historiqueDeCotation'" id="nouveauOffre" class="table table-striped table-bordered" width="100%">
                                    <thead>
                                        <tr>
                                            <th style="display: none;">Id</th>
                                            <th  style="text-align: center; width:10%;">Date</th>
                                            <th  style="text-align: center; width:15%;">N° Compteur</th>
                                            <th  style="text-align: center; width:7%;">Type Compteur</th>
                                            <th  style="text-align: center; width:7%;">Energie</th>
                                            <th  style="text-align: center; width:20%;">Lien</th>
                                            <th  style="text-align: center; width:7%;">Etat</th>
                                            <th  style="text-align: center; width:15%;">Fournisseurs</th>
                                            <th  style="text-align: center; width:10%;">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item, index) in lesOffresDemander" :key="item.id">
                                            <td style="display: none;">
                                                <small>{{item.id}}</small>
                                            </td>
                                            <td>
                                                <small>{{item.date}}</small>
                                            </td>
                                            <td>
                                                <small>
                                                    {{item.numCompteur}}
                                                </small>
                                            </td>
                                            <td>
                                                <small>{{item.compteurProfile}}</small>
                                            </td>
                                            <td>
                                                <small>{{item.typeDemande}}</small>
                                            </td>
                                            <td>
                                                <small class="td-table">
                                                        <router-link :to="{path: '/ajouter-nouvelle-offre', query: { id: item.id }}">Cliquez ici</router-link> 
                                                </small>
                                            </td>
                                            <td>
                                                <p v-if="item.etat == 'activer'">
                                                    Actif
                                                </p>
                                                <p v-if="item.etat == 'Désactiver'">
                                                    Désactiver
                                                </p>
                                            </td>
                                            <td>
                                                <small>{{item.fournisseurPossible}}</small>
                                            </td>
                                            <td>
                                                <div class="btn-group">
                                                    <v-icon
                                                        dense
                                                        class="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" 
                                                        right
                                                        >
                                                        mdi-dots-vertical
                                                    </v-icon>
                                                    <div class="dropdown-menu text-center" style="width:1px;">
                                                        <v-btn
                                                            v-if="item.etat=='Désactiver'"
                                                            @click="activerLink(item.id)"
                                                            class="dropdown-project mt-4"
                                                            color='grey lighten-1'
                                                            small
                                                            dark>
                                                            <v-icon
                                                            color='grey darken-4'>
                                                                mdi-check-outline
                                                            </v-icon>
                                                        </v-btn>
                                                        <v-btn
                                                            v-if="item.etat=='activer'"
                                                            @click="desactiverLink(item.id)"
                                                            class="dropdown-project mt-4"
                                                            color='grey lighten-1'
                                                            small
                                                            dark>
                                                            <v-icon
                                                                color='grey darken-4'>
                                                                mdi-cancel
                                                            </v-icon>
                                                        </v-btn>
                                                        <v-btn
                                                            @click="deleteLink(item.id)"
                                                            class="dropdown-project mt-4"
                                                            color='grey lighten-1'
                                                            small
                                                            dark>
                                                            <v-icon
                                                                color='grey darken-4'>
                                                                mdi-trash-can-outline
                                                            </v-icon>
                                                        </v-btn>
                                                        
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <!-- historique gaz -->
                        
                        <div class="row">
                            <div class="col-md-12 pt-2 pb-2">
                                <div class="card mb-md-0">
                                    <div class="card-body pb-6">
                                        <p class="mb-4"><span style="color:#1E6CA4" class="font-italic me-1"><b>Historique de cotation GAZ</b></span> par compteur</p>
                                        <div class="col-md-12 text-right pr-0">
                                            <router-link :to="{path: '/demande-cotation-gaz-pro', query: { siret: this.societe.siret }}">
                                                <!--<v-btn :color="$colors[0]">Nouvelle cotation </v-btn>-->
                                                <v-btn :color="$colors[0]" class="text-white">Nouvelle cotation GAZ</v-btn>
                                            </router-link> 
                                        </div>
                                        <b-table :busy="loadingTableHistoriqueGaz" show-empty :fields="fieldhistoriqueDeCotationGaz" :items="historiqueCotationGazItems" responsive bordered
                                            :per-page="perPageHisoriqueGaz"
                                            :current-page="currentPageHistoriqueGaz"
                                            id="historiqueCotationGaz">
                                            <template #empty="scope">
                                                    <noDataTable/>
                                                </template>
                                                <template #table-busy>
                                                    <div style="color:#1E6CA4" class="text-center  my-2">
                                                        <b-spinner color="yellow darken-2" class="align-middle"></b-spinner>
                                                        <strong class="ml-2">Chargement...</strong>
                                                    </div>
                                                </template>
                                            <template #cell(Action)='data'>
                                                <div class="d-flex flex-row justify-content-center">
                                                    <v-btn :loading="loadingButtonHistoriqueCotaGAZ" :color="$colors[0]" icon plain @click="ajouterVenteGazModalOpen(data.item)">
                                                        <v-icon>mdi-plus-box</v-icon>
                                                    </v-btn>
                                                </div>
                                            </template>
                                            <template #cell(date)='data'>
                                                <small>{{data.item.date}}</small>
                                            </template>

                                            <template #cell(numCompteur)='data'>
                                                <router-link :to="{path: '/new-historiqueCotationGaz', query: { id: data.item.id }}" target='_blank'   v-if="data.item.status == 'new'" ><small>Historique de Cotation {{ data.item.id}}</small></router-link> 
                                                
                                                <router-link :to="{path: '/historiqueCotationGaz', query: { id: data.item.id }}" target='_blank'  v-else ><small>{{data.item.numCompteur}}</small></router-link> 
                                            </template>

                                            <template #cell(dateDebut)='data'>
                                                <small>{{data.item.dateDebut}}</small>
                                            </template>

                                            <template #cell(dateFin)="data">
                                                <small>{{data.item.dateFin}}</small>
                                            </template>

                                            <template #cell(marge)='data'>
                                                <small>{{data.item.marge}}</small>
                                            </template>

                                        </b-table>
                                        <b-pagination
                                            v-if="currentPageHistoriqueGaz?.length > 0"
                                            v-model="currentPageHistoriqueGaz"
                                            :total-rows="historiqueCotationGazItems?.length"
                                            :per-page="perPageHisoriqueGaz"
                                            aria-controls="historiqueCotationGaz"
                                            >
                                        </b-pagination>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--historique elec-->
                        <div class="row">
                            <div class="pt-0 col-md-12">
                                <div class="card mb-md-0">
                                    <div class="card-body">
                                        <p class="mb-4"><span style="color:#1E6CA4" class="font-italic me-1"><b>Historique de cotation ELEC</b></span> par compteur</p>
                                        <div class="col-md-12 text-right pr-0">
                                            <router-link :to="{path: '/demande-cotation-elec-pro', query: { siret: this.societe.siret }}">
                                                <!--<v-btn :color="$colors[0]">Nouvelle cotation </v-btn>-->
                                                <v-btn :color="$colors[0]" class="text-white">Nouvelle cotation ELEC</v-btn>
                                            </router-link> 
                                        </div>
                                        <b-table :busy="loadingTableHistorique" show-empty :fields="fieldhistoriqueDeCotation" :items="historiqueCotationItems" responsive bordered
                                            :per-page="perPageHistorique"
                                            :current-page="currentPageHistorique"
                                            id="historiqueCotation">
                                                <template #empty="scope">
                                                    <noDataTable/>
                                                </template>
                                                <template #table-busy>
                                                    <div style="color:#1E6CA4" class="text-center my-2">
                                                        <b-spinner class="align-middle"></b-spinner>
                                                        <strong>Chargement...</strong>
                                                    </div>
                                                </template>
                                            <template #cell(date)='data'>
                                                <small>{{data.item.date}}</small>
                                            </template>

                                            <template #cell(numCompteur)='data'>
                                                <router-link :to="{path: '/new-historiqueCotation', query: { id: data.item.id }}" target='_blank'  v-if="data.item.status == 'new'" ><small>Historique de Cotation {{ data.item.id}}</small></router-link> 
                                                <router-link :to="{path: '/historiqueCotation', query: { id: data.item.id }}" target='_blank' v-else ><small>{{data.item.numCompteur}}</small></router-link> 
                                            </template>

                                            <template #cell(optionTarifaire)='data'>
                                                <small>{{data.item.optionTarifaire}}</small>
                                            </template>

                                            <template #cell(dateDebut)='data'>
                                                <small>{{data.item.dateDebut}}</small>
                                            </template>

                                            <template #cell(dateFin)="data">
                                                <small>{{data.item.dateFin}}</small>
                                            </template>

                                            <template #cell(marge)='data'>
                                                <small>{{data.item.marge}}</small>
                                            </template>

                                        </b-table>
                                        <b-pagination
                                            v-if="currentPageHistorique?.length > 0"
                                            v-model="currentPageHistorique"
                                            :total-rows="historiqueCotationItems?.length"
                                            :per-page="perPageHistorique"
                                            aria-controls="historiqueCotation"
                                            >
                                        </b-pagination>
                                    </div>
                                </div>
                            </div>
                        </div>
                       
                    </div> 

                    <!-- commented historique gaz elec -->
                    <div class="col-lg-8 pl-lg-0 pl-2 pr-2" v-if="vueAfficher=='listeVentesHistoriqueByCompte'"> 
                        <div class="row">
                            <div class="col-md-12 pt-2">
                                <div class="card mb-4 mb-md-0">
                                    <div class="card-body" style="overflow:overlay">
                                        <p class="mb-4"><b><span style="color:#1E6CA4" class="font-italic me-1">Historique de cotation </span></b> par compte</p>
                                        
                                        <historiqueCotationCpElec
                                        :societe="societe.id" 
                                            /> 
                                                          
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-12 pt-2">
                                <div class="card mb-4 mb-md-0">
                                    <div class="card-body" style="overflow:overlay">
                                        <p class="mb-4"><b><span style="color:#1E6CA4" class="font-italic me-1">Historique de cotation Gaz </span></b> par compte</p>
                                        
                                        <historiqueCotationCpGaz :societe="societe.id" /> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    
                    </div>

                    <div class="col-lg-8 pl-lg-0 px-2"  v-if="vueAfficher=='listeDesVentePro'" >
                        <div class="card mb-4">
                            <div class="card-body">
                                <p class="mb-11"><b><span style="color:#1E6CA4" class="font-italic me-1">Les ventes professionnelles </span></b></p>
                                <!--<div class="col-md-12 bg-light text-right">
                                        <button @click="ajouterUneVentePro" type="button" class="btn btn-primary">Nouvelle Vente</button>
                                </div>-->
                               <b-table :busy="loadingTableVentePro" show-empty :fields="fieldVentePro" :items="venteProSocieteItems" responsive bordered
                                    :per-page="perPageVentePro"
                                    :current-page="currentPageVentePro"
                                    id="venteProSociete">
                                            <template #empty="scope">
                                                <noDataTable/>
                                            </template>
                                            <template #table-busy>
                                                 <div style="color:#1E6CA4" class="text-center my-2">
                                                    <b-spinner class="align-middle"></b-spinner>
                                                    <strong class="ml-2">Chargement...</strong>
                                                </div>
                                            </template>
                                        <template #cell(date)='data'>
                                        <small>{{data.item.date}}</small>
                                        </template>

                                        <template #cell(Vendeur)='data'>
                                           <small>{{data.item.Vendeur}}</small>
                                        </template>

                                        <template #cell(numCompteur)='data'>
                                             <small>{{data.item.numCompteur}}</small>
                                        </template>

                                        <template #cell(type)='data'>
                                            <small>{{data.item.type}}</small>
                                        </template>

                                        <template #cell(valeur)="data">
                                             <small>{{data.item.valeur}}</small>
                                        </template>

                                        <template #cell(status)='data'>
                                            <div class="btn-group">
                                                    <small v-if="data.item.status == 'Refuser'"><v-icon dense style="color:red">mdi-close-circle</v-icon><b class="ml-2">refusée</b></small>
                                                    <small v-if="data.item.status == 'Accepter'"><v-icon dense style="color:#00E676">mdi-check-circle</v-icon><b class="ml-2">acceptée</b></small>
                                                    <small v-if="data.item.status == 'en Attend'"><v-icon>mdi-timer-refresh-outline</v-icon dense style="color:gray"><b class="ml-2">en attente</b></small>
                                                    <small v-if="data.item.status == 'en attente de validation'"><v-icon dense style="color:#0D47A1">mdi-send-check</v-icon><b>Phase de validation</b></small>
                                                </div>
                                        </template>

                                        </b-table>
                                        <b-pagination
                                            v-if="venteProSocieteItems?.length > 0"
                                            v-model="currentPageVentePro"
                                            :total-rows="venteProSocieteItems?.length"
                                            :per-page="perPageVentePro"
                                            aria-controls="venteProSociete"
                                            >
                                        </b-pagination>
                            </div>
                        </div>
                    </div>
                <div class="col-lg-8 pl-lg-0 pl-2 pr-2" v-if="vueAfficher=='GenerationPDF'">
                        <div class="card mb-3">
                            <div class="card-body pb-9">
                                <p class="mb-8"><b><span style="color:#1E6CA4" class="font-italic me-1">Les Documents </span></b>disponibles</p>
                                 <div class="d-flex justify-content-left mb-2 mt-2">
                                     <v-select
                                        required
                                        outlined
                                        v-model="listCompteurSelected"
                                        :items="customItems"
                                        @change="changeClick"
                                        label="Document"
                                        item-value="value"
                                        item-text="text"
                                        return-object
                                        hide-details
                                        >
                                        <!-- <template v-slot:item="data">
                                            <v-btn :color="data.item.color" @click="data.item.action" class="ma-2">
                                            <span class="white--text font-weight-bold">
                                                {{ data.item.text }}
                                                <v-icon class="ml-1" dense>{{ data.item.icon }}</v-icon>
                                            </span>
                                            </v-btn>
                                        </template> -->
                                    </v-select>
                                </div>
                            </div>
                        </div>
                        <div>
                            <lettreDeRisiliation v-if="openResiliationModal" :societe="societe" :listCompteur="listCompteur" @closeResiliationModal="openResiliationModal=false"/>
                            <MandatResiliation v-if="openMandatModal" :societe="societe" :listCompteur="listCompteur" @closeMandatModal="openMandatModal = false"/>
                            <ExcelEkwateur v-if="openExcelEkwateurModal" :societe="societe" :listCompteur="listCompteur" @closeMandatModal="openExcelEkwateurModal = false" />
                            <infoTosheet :societe="societe" :openInfoToSheetModal="openInfoToSheetModal" @closeInfoToSheetModal="openInfoToSheetModal=false"/>
                        </div>
                    </div>
                </div>
        </div>
    </section>
</div>
</template>

<script>
    import "jquery/dist/jquery.min.js";
    import "bootstrap/dist/css/bootstrap.min.css";
    import "bootstrap/dist/css/bootstrap.css";
    import "bootstrap/dist/js/bootstrap.bundle";
    import "datatables.net-dt/js/dataTables.dataTables";
    import "datatables.net-dt/css/jquery.dataTables.min.css";
    import $ from 'jquery'
    import jQuery from 'jquery'
    import axios from 'axios'
    import infoTosheet from "./componentDocs/InfoToSheet/InfoToSheet.vue"
    import loadingComponent from "../../components/ui/loading-component.vue"
    import ajouterVenteElecComposent from "./ajouterVenteElecComposant.vue"
    import ListeDesPropositions from '../../components/proposition-professionnels-elec/ListeDesPropositions.vue'
    import AjouterVentePro from '../vente-pro/AjouterVentePro.vue'
    import noDataTable from "../../components/no-data-table.vue"

    import MandatResiliation from "./componentDocs/mandat-resiliation/MandatResiliation.vue"
    import lettreDeRisiliation from "./componentDocs/resiliation/resiliation.vue"
    import historiqueCotationCpElec from "@/views/historiqueCotationCpElec.vue"
    import historiqueCotationCpGaz from "@/views/historiqueCotationCpGaz.vue"
    import ExcelEkwateur from "./componentDocs/excel/ExcelEkwateur.vue"
 

    export default{
        components: {
            ExcelEkwateur,
            ListeDesPropositions,
            AjouterVentePro,
            loadingComponent,
            ajouterVenteElecComposent,
            noDataTable,
            lettreDeRisiliation,
            historiqueCotationCpElec,
            historiqueCotationCpGaz,
            MandatResiliation,
            infoTosheet
        },
        name: 'profileSociete',
        data() {
            return {
                listCompteurSelected:"",
                customItems: [
                    {
                        text: 'Lettre de résiliation',
                        value: 'resiliation',
                        color: 'primary',
                        icon: 'mdi-download',
                        action: this.openResiliation
                    },
                    {
                        text: 'Mandat de résiliation',
                        value: 'mandat',
                        color: 'primary',
                        icon: 'mdi-download',
                        action: this.openMandat
                    },
                    {
                        text: 'Création Excel Offre Ekwateur',
                        value: 'pdf',
                        color: 'primary',
                        icon: 'mdi-download',
                        action: this.openExcelEkwateur
                    },
                    {
                        text: 'Ajouter Offres Elmy (Sheet)',
                        value: 'ajouter',
                        color: 'primary',
                        icon: 'mdi-download',
                        action: this.addInfo
                    }
                ],
                openExcelEkwateurModal:false,
                openMandatModal:false,
                openResiliationModal: false,
                openInfoToSheetModal: false,
                openMandatModal:false,
                listCompteur: [],
                typeUser: "",

                loadingButtonHistoriqueCotaElec:false,
                loadingButtonHistoriqueCotaGAZ : false,
                pourcentage: 0,
                listeCompteurVente: [],
                dataPourVente: null,
                ajouterVenteElecModal: false,
                currentPageHistorique:1,
                perPageHistorique:4,
                currentPageHistoriqueGaz:1,
                perPageHisoriqueGaz: 4,
                currentPageVentePro:1,
                perPageVentePro: 10,
                currentPageDC:1,
                perPageDC: 4,
                urlStatus: "",
                historiqueCotationItems:[],
                historiqueCotationGazItems: [],
                demandeDeCotationItems:[],
                venteProSocieteItems:[],                

                loadingTableHistorique : false,
                loadingTableHistoriqueGaz : false,
                loadingTableCotation:false,
                loadingTableVentePro:false,
                fieldhistoriqueDeCotationGaz:[
                    {thStyle:'background-color:#1A1A1A;color:white;font-weight:bold',key:'date', label:"Date d'ajout"},
                    {thStyle:'background-color:#1A1A1A;color:white;font-weight:bold',key:"numCompteur", label:"Numéro de compteur"},
                    {thStyle:'background-color:#1A1A1A;color:white;font-weight:bold',key:"dateDebut", label:"Début de contrat"},
                    {thStyle:'background-color:#1A1A1A;color:white;font-weight:bold',key:"dateFin", label:"Fin de contrat"},
                    {thStyle:'background-color:#1A1A1A;color:white;font-weight:bold',key:"marge",label:"Marge"}
                ],
                fieldDemandeDeCotation:[
                    {thStyle:'background-color:#1A1A1A;color:white;font-weight:bold',key: 'date', label:"Date d'ajout" },
                    {thStyle:'background-color:#1A1A1A;color:white;font-weight:bold',key: 'typeDemande', label:"Énergie"},
                    {thStyle:'background-color:#1A1A1A;color:white;font-weight:bold',key:"numCompteur",label:"Numéro de compteur"},
                    {thStyle:'background-color:#1A1A1A;color:white;font-weight:bold',key:"Echeance", label:"Début de contrat"},
                    {thStyle:'background-color:#1A1A1A;color:white;font-weight:bold',key:"finContrat", label:"Fin de contrat"},
                    {thStyle:'background-color:#1A1A1A;color:white;font-weight:bold',key:"statut",label:"Statut"},
                    {thStyle:'background-color:#1A1A1A;color:white;font-weight:bold',key:"lienFournisseur", label:"Lien fournisseur"}
                ],


                
                fieldhistoriqueDeCotation:[
                    {thStyle:'background-color:#1A1A1A;color:white;font-weight:bold',key:'date', label:"Date d'ajout"},
                    {thStyle:'background-color:#1A1A1A;color:white;font-weight:bold',key:"numCompteur", label:"Numéro de compteur"},
                    {thStyle:'background-color:#1A1A1A;color:white;font-weight:bold',key: "optionTarifaire", label:"Option tarifaire"},
                    {thStyle:'background-color:#1A1A1A;color:white;font-weight:bold',key:"dateDebut", label:"Début de contrat"},
                    {thStyle:'background-color:#1A1A1A;color:white;font-weight:bold',key:"dateFin", label:"Fin de contrat"},
                    {thStyle:'background-color:#1A1A1A;color:white;font-weight:bold',key:"marge",label:"Marge"}
                ],

                fieldVentePro:[
                    {thStyle:'background-color:#1A1A1A;color:white;font-weight:bold',key:'date', label:"Date d'ajout"},
                    {thStyle:'background-color:#1A1A1A;color:white;font-weight:bold',key:"Vendeur", label:"Commercial"},
                    {thStyle:'background-color:#1A1A1A;color:white;font-weight:bold',key: "type", label:"Énergie"},
                    {thStyle:'background-color:#1A1A1A;color:white;font-weight:bold',key:"valeur", label:"Valeur"},
                    {thStyle:'background-color:#1A1A1A;color:white;font-weight:bold',key:"status", label:"Statut"},
                ],
                dialog: false,
                loading: false,
                ajouterVentePro: false,
                vueAfficher: 'client',
                societe: null,
                historiqueDeCotation: null,
                lesOffresDemander: null,
                demandeDeCotationDeSociete: null,
                venteProSociete: null,
                compteVentePro: null,
                editMode: false,
                nouveauOffre:{
                    lesFournisseurPossible: [],
                    numCompteur: null,
                    dateDebut: null,
                },
                dialogDemandeOffre:false,
                idDemandeDeOffre: null,
                typeDeOffre: "ELEC",
                venteTypeEnergie: 'ELEC',
                profilGaz: null,
                listFournisseur: [
                    'Alpiq',
                    'Alterna',
                    'Antargaz',
                    'Axpo',
                    'BARRY',
                    'BULB',
                    'BUTAGAZ',
                    'CDISCOUNT',
                    'Dyneff',
                    'Edenkia',
                    'EDF',
                    'EDS',
                    'EDSB',
                    'Ekwateur',
                    'Elecocite',
                    'Electricite_de_provence',
                    'Electricite_de_savoie',
                    'Electricite_de_Strasbourg',
                    'ELMY',
                    'Enalp',
                    'Endesa',
                    'Enercoop',
                    'Energem',
                    'ENERGIE_DICI',
                    'Engie',
                    'ENI',
                    'Enovos',
                    'Gaz_de_Bordeaux',
                    'GazelEnergie',
                    'Gazprom',
                    'GEDIA',
                    'GEG',
                    'GREEN_YELLOW',
                    'HAPPE',
                    'IBERDROLA',
                    'ILEK',
                    'JPME',
                    'la_bellenergie',
                    'LECLERCQ',
                    'LLUM',
                    'LUCIA',
                    'MEGA',
                    'MET-France',
                    'mint-energie',
                    'OHM',
                    'OVO',
                    'Picoty',
                    'PLUM',
                    'Primeo',
                    'Proxelia',
                    'Redeéo',
                    'SaveEnergies',
                    'Selia',
                    'SEFE',
                    'SOWEE',
                    'Synelva',
                    'TDE',
                    'Urban_Solar',
                    'Vattenfall',
                    'Volterres',
                    'Wekiwi',
                    'yeli-xs',
                ],
                lesCompteurPossible: []
            }
        },
        watch: {
            async vueAfficher(newVal){
                if(newVal == "GenerationPDF"){
                    this.listCompteurSelected = this.customItems[0]
                    await this.listCompteurSelected.action()
                }
            }
        },
        async created() {
            this.typeUser = localStorage.getItem('typeUser')
            var whoIam = ""
            await this.whoIAm(this.getToken('token')).then((data)=>{whoIam=data});
            if(whoIam == "noToken"){
                localStorage.removeItem('vendeurName')
                localStorage.removeItem('typeUser') 
                localStorage.removeItem('token')
                this.$router.push('/login')
            }else{
                localStorage.setItem('typeUser', whoIam)
            }
            await this.getSocieteById();
        },
        methods: {
            async changeClick(){
                await this.listCompteurSelected.action()
            },
             async GenerationPDF(){
                await this.DemandeDeCotation()
                this.vueAfficher = "GenerationPDF"
            },
            async openResiliation(){
                this.listCompteur = []
                for (var i =0; i<this.demandeDeCotationItems.length; i++){
                    var lesNumCompteur = this.demandeDeCotationItems[i].numCompteur.split(',')
                    var typeDemande = this.demandeDeCotationItems[i].typeDemande
                    for (var j=0; j<lesNumCompteur.length; j++){
                        this.listCompteur.push({
                            numCompteur: lesNumCompteur[j],
                            typeDemande: typeDemande
                        })
                    }
                }
                this.openResiliationModal = true
                this.openMandatModal = false;
                this.openExcelEkwateurModal = false;
                this.openInfoToSheetModal = false;
            },
            async openMandat(){
                this.listCompteur = []
                for (var i =0; i<this.demandeDeCotationItems.length; i++){
                    var lesNumCompteur = this.demandeDeCotationItems[i].numCompteur.split(',')
                    var typeDemande = this.demandeDeCotationItems[i].typeDemande
                    for (var j=0; j<lesNumCompteur.length; j++){
                        this.listCompteur.push({
                            numCompteur: lesNumCompteur[j],
                            typeDemande: typeDemande
                        })
                    }
                }
                this.openMandatModal = true
                this.openResiliationModal = false
                this.openExcelEkwateurModal = false;
                this.openInfoToSheetModal = false;
            },
            async openExcelEkwateur(){
               this.listCompteur = []
                for (var i =0; i<this.demandeDeCotationItems.length; i++){
                    var lesNumCompteur = this.demandeDeCotationItems[i].numCompteur.split(',')
                    var typeDemande = this.demandeDeCotationItems[i].typeDemande
                    for (var j=0; j<lesNumCompteur.length; j++){
                        this.listCompteur.push({
                            numCompteur: lesNumCompteur[j],
                            typeDemande: typeDemande
                        })
                    }
                }
                this.openMandatModal = false
                this.openResiliationModal = false
                this.openInfoToSheetModal = false;
                this.openExcelEkwateurModal = true;  
            },
            async addInfo(){
                this.openInfoToSheetModal = true
                this.openMandatModal = false
                this.openResiliationModal = false
                this.openExcelEkwateurModal = false;  
            },
            async getPourcentage(numCompteur){
                var formData = new FormData()
                formData.append('numCompteur', numCompteur)
                await axios({
                    url:
                    process.env.VUE_APP_URL_API_CLIENT +
                    'getPourcentageCompte/',
                    method: 'POST',
                    data: formData,
                })
                .then((res) => {
                    this.pourcentage = res.data.result
                }).catch((err)=>{
                    this.pourcentage = 0
                })
            },
            async ajouterVenteElecModalOpen(item){
                this.loadingButtonHistoriqueCotaElec = true;
                await this.getPourcentage(item.numCompteur)
                await this.getListeCompteurByDC(item.numCompteur)
                this.dataPourVente = item
                this.venteTypeEnergie= "ELEC"
                this.ajouterVenteElecModal = true;
                this.loadingButtonHistoriqueCotaElec = false;
            },
            async getListeCompteurByDC(numCompteur){
                var formData = new FormData()
                formData.append('numCompteur', numCompteur)
                await axios({
                    url:
                    process.env.VUE_APP_URL_API_CLIENT +
                    'getAllCompteurParDC/',
                    method: 'POST',
                    data: formData,
                })
                .then((res) => {
                    this.listeCompteurVente = res.data.result
                }).catch((err)=>{
                    this.listeCompteurVente = []
                })

            },
            async ajouterVenteGazModalOpen(item){
                this.loadingButtonHistoriqueCotaGAZ = true;
                await this.getPourcentage(item.numCompteur)
                await this.getListeCompteurByDC(item.numCompteur)
                this.dataPourVente = item
                this.venteTypeEnergie= "GAZ"
                this.ajouterVenteElecModal = true;
                this.loadingButtonHistoriqueCotaGAZ = false;
            },
            async ajouterVenteElecModalClose(){
                this.ajouterVenteElecModal = false;
            },
            async DemandeDeCotation(){
                this.loadingTableHistorique= true;
                this.loadingTableCotation = true;
                this.loadingTableHistoriqueGaz = true;
                await this.getAllHistoryCotationBySociete();
                await this.getAllHistoryCotationGazBySociete();
                this.loadingTableHistorique= false;
                this.loadingTableCotation = false;
                this.loadingTableHistoriqueGaz = false;
            },            
            async deleteLink(id){
                var formData = new FormData()
                formData.append('token', this.getToken('token'))
                formData.append('id', id)
                await axios({
                    url:
                    process.env.VUE_APP_URL_API_CLIENT +
                    'deleteLink/',
                    method: 'POST',
                    data: formData,
                })
                .then(async (res) => {
                    if(res.data.result == true){
                        alert("Lien supprimé")
                        await this.getLink()
                    }else if(res.data.result == false){
                        alert("Impossible de supprimer ce lien")
                    }else if(res.data.result == "permission"){
                        alert('permission refusée')
                        localStorage.removeItem('vendeurName')
                        localStorage.removeItem('typeUser') 
                        localStorage.removeItem('token')
                        this.$router.push('/login')
                    }
                }).catch((err)=>{
                    console.log(err)
                })

            },
            async desactiverLink(id){
                var formData = new FormData()
                formData.append('token', this.getToken('token'))
                formData.append('id', id)
                await axios({
                    url:
                    process.env.VUE_APP_URL_API_CLIENT +
                    'disactiverLink/',
                    method: 'POST',
                    data: formData,
                })
                .then(async (res) => {
                    if(res.data.result == true){
                        alert("Lien désactivé")
                        await this.getLink()
                    }else if(res.data.result == false){
                        alert("Impossible de désactiver ce lien")
                    }else if(res.data.result == "permission"){
                        alert('permission refusée')
                        localStorage.removeItem('vendeurName')
                        localStorage.removeItem('typeUser') 
                        localStorage.removeItem('token')
                        this.$router.push('/login')
                    }
                }).catch((err)=>{
                    console.log(err)
                })

            },
            async activerLink(id){
                var formData = new FormData()
                formData.append('token', this.getToken('token'))
                formData.append('id', id)
                await axios({
                    url:
                    process.env.VUE_APP_URL_API_CLIENT +
                    'activerLink/',
                    method: 'POST',
                    data: formData,
                })
                .then(async (res) => {
                    if(res.data.result == true){
                        alert("Lien activé")
                        await this.getLink()
                    }else if(res.data.result == false){
                        alert("Impossible d'activer ce lien")
                    }else if(res.data.result == "permission"){
                        alert('permission refusée')
                        localStorage.removeItem('vendeurName')
                        localStorage.removeItem('typeUser') 
                        localStorage.removeItem('token')
                        this.$router.push('/login')
                    }
                }).catch((err)=>{
                    console.log(err)
                })
            },
            async ajouterNouveauOffre(){
                this.$refs.formNouveauOffre.validate()
                if(this.$refs.formNouveauOffre.validate()){
                    var formData = new FormData()
                    formData.append('token', this.getToken('token'))
                    formData.append('id', this.idDemandeDeOffre)
                    formData.append('lesFournisseurPossible', this.nouveauOffre.lesFournisseurPossible)
                    formData.append('numCompteur', this.nouveauOffre.numCompteur)
                    formData.append('dateDebut', this.nouveauOffre.dateDebut)
                    formData.append('typeDeOffre', this.typeDeOffre)
                    formData.append('profile', this.profilGaz)
                    await axios({
                        url:
                        process.env.VUE_APP_URL_API_CLIENT +
                        'createLinkToGetNewOffre/',
                        method: 'POST',
                        data: formData,
                    })
                    .then(async (res) => {
                        if(res.data.result == true){
                            alert("La demande a été ajouté avec succès !")
                            this.fermerChoisirOffre()
                            await this.getLink()
                        }else if(res.data.result == false){
                            alert("Aucunes informations enedis pour ce compteur pour le moment")
                        }else if(res.data.result == "permission"){
                            alert('permission refusée')
                            localStorage.removeItem('vendeurName')
                            localStorage.removeItem('typeUser') 
                            localStorage.removeItem('token')
                            this.$router.push('/login')
                        }
                    }).catch((err)=>{
                        console.log(err)
                    })
                    
                    
                }
            },
            fermerChoisirOffre(){
                this.dialogDemandeOffre=false
                this.idDemandeDeOffre=null
                this.typeDeOffre = null
                this.profilGaz = null
                this.nouveauOffre.lesFournisseurPossible=[]
                this.numCompteur=null
                this.$refs.formNouveauOffre.resetValidation();
            },
            async getLink(){
                var formData = new FormData()
                formData.append('token', this.getToken('token'))
                formData.append('idSociete', this.$route.query.id)
                await axios({
                    url:
                    process.env.VUE_APP_URL_API_CLIENT +
                    'getLinkDesOffresBySociete/',
                    method: 'POST',
                    data: formData,
                })
                .then((res) => {
                    if(res.data.result==true){
                        this.lesOffresDemander = res.data.liens
                    }else if(res.data.result==false){
                    }else if(res.data.result=="permission"){
                        alert('permission refusée')
                        localStorage.removeItem('vendeurName')
                        localStorage.removeItem('typeUser') 
                        localStorage.removeItem('token')
                        this.$router.push('/login')
                    }
                }).catch((err)=>{
                    alert('permission refusée')
                    localStorage.removeItem('vendeurName')
                    localStorage.removeItem('typeUser') 
                    localStorage.removeItem('token')
                    this.$router.push('/login')
                })
            },
            choisirOffre(id, type){
                var formData = new FormData()
                formData.append('token', this.getToken('token'))
                formData.append('id', id)
                axios({
                    url:
                    process.env.VUE_APP_URL_API_CLIENT +
                    'getDemandeDeCotationById/',
                    method: 'POST',
                    data: formData,
                })
                .then((res) => {
                    if(res.data.result == true){
                        this.dialogDemandeOffre=true
                        this.typeDeOffre = type
                        this.idDemandeDeOffre=id
                        this.lesCompteurPossible = res.data.listCompteur

                    }else if(res.data.result == "permission"){
                        alert('permission refusée')
                        localStorage.removeItem('vendeurName')
                        localStorage.removeItem('typeUser') 
                        localStorage.removeItem('token')
                        this.$router.push('/login')
                    }else{
                        alert("la demande n'existe pas pour le moment !")
                    }
                }).catch((err)=>{
                    alert("la demande n'existe pas pour le moment !")
                    console.log(err)
                })
                
            },
            activeEdit(){
                this.editMode = true;    
            },
            
            cancelEdit(){
                this.editMode = false;    
            },
            saveChange(){
                var formData = new FormData()
                formData.append('token', this.getToken('token'))
                formData.append('id', this.societe.id)
                formData.append('nom', this.societe.nom) 
                formData.append('prenom', this.societe.prenom)
                formData.append('AdresseEmail', this.societe.AdresseEmail)
                formData.append('numTele', this.societe.numTele)
                formData.append('fonction', this.societe.fonction)
                axios({
                    url:
                    process.env.VUE_APP_URL_API_CLIENT +
                    'saveChangeSociete/',
                    method: 'POST',
                    data: formData,
                })
                .then((res) => {
                    if(res.data.result==true){
                        alert('données mises à jour')
                        this.editMode = false;   
                    }else if(res.data.result==false){
                        alert("Une erreur est survenue")
                        this.editMode = false;   
                    }else if(res.data.result=="permission"){
                        alert('permission refusée')
                        localStorage.removeItem('vendeurName')
                        localStorage.removeItem('typeUser') 
                        localStorage.removeItem('token')
                        this.$router.push('/login')
                    }
                }).catch((err)=>{
                    alert('permission refusée')
                    localStorage.removeItem('vendeurName')
                    localStorage.removeItem('typeUser') 
                    localStorage.removeItem('token')
                    this.$router.push('/login')
                })
            },
            ajouterUneVentePro(){
                this.ajouterVentePro = true
            },
            afficherInfoPersonnelle(){
                this.vueAfficher = 'client'  
                this.demandeDeCotationDeSociete=null
                this.historiqueDeCotation=null
                this.venteProSociete=null
                this.compteVentePro=null
            },
            spliteList(list){
                return list.split(',')
            },
            spliteListSlice(list){
                return list.split(',').slice(1,)
            },
            
            dateFormatSlach(date){
                return date.split('-').reverse().join('/')
            },
            async getAllVenteProBySociete(){
                this.loadingTableVentePro = true;
                this.demandeDeCotationItems=[]
                this.historiqueCotationItems=[]
                this.historiqueCotationGazItems=[]
                this.vueAfficher="listeDesVentePro"
                var formData = new FormData()
                formData.append('token', this.getToken('token'))
                formData.append('idSociete', this.$route.query.id)
               await axios({
                    url:
                    process.env.VUE_APP_URL_API_CLIENT +
                    'getAllVenteProBySociete/',
                    method: 'POST',
                    data: formData,
                })
                .then((res) => {
                    if(res.data.result==true){
                        this.venteProSocieteItems = res.data.ventes
                        this.compteVentePro = res.data.comptes
                        this.loadingTableVentePro = false;
                    }else if(res.data.result==false){
                        alert("Une erreur est survenue")
                    }else if(res.data.result=="permission"){
                        this.loadingTableVentePro = false;
                        alert('permission refusée')
                        localStorage.removeItem('vendeurName')
                        localStorage.removeItem('typeUser') 
                        localStorage.removeItem('token')
                        this.$router.push('/login')
                    }
                }).catch((err)=>{
                    alert('permission refusée')
                    localStorage.removeItem('vendeurName')
                    localStorage.removeItem('typeUser') 
                    localStorage.removeItem('token')
                    this.$router.push('/login')
                })
                this.loadingTableVentePro = false;
            },
            getAllVenteBySocieteCompte(){
                this.vueAfficher="listeVentesHistoriqueByCompte"
                this.venteProSociete=null
                this.compteVentePro=null
                
            },
            async GenerationPDF(){
                await this.DemandeDeCotation()
                this.vueAfficher = "GenerationPDF"
            },
            
            async getAllHistoryCotationBySociete(){
                await this.getLink();
                this.venteProSociete=null
                this.compteVentePro=null
                this.vueAfficher="historiqueDeCotation"
                var formData = new FormData()
                formData.append('token', this.getToken('token'))
                formData.append('idSociete', this.$route.query.id)
                await axios({
                    url:
                    process.env.VUE_APP_URL_API_CLIENT +
                    'getAllHistoryCotationBySociete/',
                    method: 'POST',
                    data: formData,
                })
                .then((res) => {
                    if(res.data.result==true){
                        this.vueAfficher="historiqueDeCotation"
                        this.historiqueCotationItems = res.data.histoDeCotation
                        this.demandeDeCotationItems = res.data.DC
                    }else if(res.data.result==false){
                        alert("Une erreur est survenue")
                    }else if(res.data.result=="permission"){
                        alert('permission refusée')
                        localStorage.removeItem('vendeurName')
                        localStorage.removeItem('typeUser') 
                        localStorage.removeItem('token')
                        this.$router.push('/login')
                    }
                }).catch((err)=>{
                    alert('permission refusée')
                    localStorage.removeItem('vendeurName')
                    localStorage.removeItem('typeUser') 
                    localStorage.removeItem('token')
                    this.$router.push('/login')
                })
            },
            async getAllHistoryCotationGazBySociete(){
                await this.getLink()
                this.venteProSociete=null
                this.compteVentePro=null
                this.vueAfficher="historiqueDeCotation"
                var formData = new FormData()
                formData.append('token', this.getToken('token'))
                formData.append('idSociete', this.$route.query.id)
                await axios({
                    url:
                    process.env.VUE_APP_URL_API_CLIENT +
                    'getAllHistoryCotationGazBySociete/',
                    method: 'POST',
                    data: formData,
                })
                .then((res) => {
                    if(res.data.result==true){
                        this.vueAfficher="historiqueDeCotation"
                        this.historiqueCotationGazItems = res.data.histoDeCotation
                        this.demandeDeCotationItems = res.data.DC
                    }else if(res.data.result==false){
                        alert("Une erreur est survenue")
                    }else if(res.data.result=="permission"){
                        alert('permission refusée')
                        localStorage.removeItem('vendeurName')
                        localStorage.removeItem('typeUser') 
                        localStorage.removeItem('token')
                        this.$router.push('/login')
                    }
                }).catch((err)=>{
                    alert('permission refusée')
                    localStorage.removeItem('vendeurName')
                    localStorage.removeItem('typeUser') 
                    localStorage.removeItem('token')
                    this.$router.push('/login')
                })
            },
            getSocieteById(){
                
                this.dialog = true
                this.loading = true
                var formData = new FormData()
                formData.append('token', this.getToken('token'))
                formData.append('id', this.$route.query.id)
                axios({
                    url:
                    process.env.VUE_APP_URL_API_CLIENT +
                    'getSocieteById/',
                    method: 'POST',
                    data: formData,
                })
                .then((res) => {
                    this.dialog = false
                    this.loading = false
                    if(res.data.result==true){
                        this.societe = res.data.societe
                        this.urlStatus = res.data.status
                    }else if(res.data.result==false){
                        alert("Une erreur est survenue")
                    }else if(res.data.result=="permission"){
                        alert('permission refusée')
                        localStorage.removeItem('vendeurName')
                        localStorage.removeItem('typeUser') 
                        localStorage.removeItem('token')
                        this.$router.push('/login')
                    }
                }).catch((err)=>{
                    this.urlStatus = ""
                    this.dialog = false
                    this.loading = false
                    alert('permission refusée')
                    localStorage.removeItem('vendeurName')
                    localStorage.removeItem('typeUser') 
                    localStorage.removeItem('token')
                    this.$router.push('/login')
                })
            }
        }
    }

</script>


