<template>
  <div class="ma-3 d-flex flex-row align-items-end">
    <loadingComponent
      @closeDialog="dialog = $event"
      :loadingProps="loading"
      :dialogProps="dialog"
      :messageDialogeProps="messageDialoge"
      :errorProps="error"
    />
    <v-btn
      v-if="typeContratProps == 'gaz'"
      style="width-button-responsive"
      depressed
      elevation="1"
      large
      :color="$colors[0]"
      class="text-white align-self-end"
      @click="generatePdf('GAZ')"
      >Examiner le document</v-btn
    >
    <v-btn
      v-if="typeContratProps == 'elec'"
      style="width-button-responsive"
      depressed
      elevation="1"
      large
      :color="$colors[0]"
      class="text-white align-self-end"
      @click="generatePdf('ELEC')"
      >Examiner le document</v-btn
    >
    <v-btn
      v-if="typeContratProps == 'elec_et_gaz'"
      style="width-button-responsive"
      depressed
      elevation="1"
      large
      :color="$colors[0]"
      class="text-white align-self-end"
      @click="generatePdf()"
      >Examiner le document</v-btn
    >
  </div>
</template>

<script>
import loadingComponent from "../components/ui/loading-component.vue";
import axios from "axios";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import dialogeModal from "../components/ui/dialogeModal.vue";
import dayjs from "dayjs";
import {
  degrees,
  grayscale,
  PDFDocument,
  rgb,
  StandardFonts,
  layoutMultilineText,
} from "pdf-lib";
import download from "downloadjs";
import "jspdf-autotable";

export default {
  name: "AutorisationDeCollectionDeDonneesSigne",
  components: {
    dialogeModal,
    loadingComponent,
  },
  props: [
    "typeContratProps",
    "RCSProps",
    "siretProps",
    "sirenProps",
    "adressePostaleProps",
    "raisonProps",
    "nomProps",
    "prenomProps",
    "emailProps",
    "fonctionProps",
    "numTelProps",
    "compteurGazProps",
    "compteurElecProps",
    "formRef",
  ],
  data() {
    return {
      dialog: false,
      error: false,
      loading: false,
      messageDialoge: "L'e-mail a été envoyé avec succès",
      acdGenerationType: "GAZ",
      formRefModel: this.formRef,
    };
  },
  watch: {
    formRef: function (newValue) {
      console.log(newValue);
      this.formRefModel = newValue;
    },
  },
  methods: {
    formatPhoneNumber(phoneNumber) {
      // Supprimer tous les caractères non numériques
      phoneNumber = phoneNumber.replace(/\D/g, "");

      // Insérer un espace après chaque paire de chiffres
      phoneNumber = phoneNumber.replace(/(\d{2})(?=\d)/g, "$1 ");

      return phoneNumber;
    },
    findCodePostal(adresse) {
      console.log(adresse);
      // Expression régulière pour rechercher un code postal français
      let regex = /\b\d{5}\b/g;

      let codesPostal = adresse.match(regex);
      if (codesPostal) {
        return codesPostal;
      } else {
        return null;
      }
    },
    async getImage(link, doc) {
      const ImageLogo = await fetch(link).then((res) => res.arrayBuffer());

      var pngImage = await doc.embedPng(ImageLogo);
      return pngImage;
    },
    async generatePdf(typePDF) {
      if (this.formRefModel.validate()) {
        this.acdGenerationType = typePDF;
        if (this.compteurElecProps.length > 0) {
          var formDonne = new FormData();
          formDonne.append("numCompteur", this.compteurElecProps.join());
          formDonne.append("token", this.getToken("token"));
          let valid = false;
          await axios({
            url: process.env.VUE_APP_URL_API_CLIENT + "verifCompteurExist/",
            method: "POST",
            data: formDonne,
          })
            .then(async (res) => {
              if (res.data.result === "permission") {
                localStorage.removeItem("vendeurName");
                localStorage.removeItem("typeUser");
                localStorage.removeItem("token");
                this.$router.push("/login");
                valid = false;
              }
              if (res.data[0].result === "valide") {
                valid = true;
              } else if (res.data[0].result === "notValide") {
                this.dialog = false;
                this.loading = false;
                this.error = false;
                this.missedCompteur = res.data[0].compteurError;
                this.dialog = true;
                this.error = true;
                this.messageDialoge =
                  "le compteur n°" + this.missedCompteur + " n'existe pas";
              } else if (res.data[0].result === "dataErreur") {
                this.dialog = false;
                this.loading = false;
                this.error = false;
                this.dialog = true;
                this.error = true;
                this.messageDialoge = "veuillez vérifier vos données.";
              } else {
                this.dialog = false;
                this.loading = false;
                this.error = false;
                this.dialog = true;
                this.error = true;
                this.messageDialoge = "Il semble qu'une erreur soit survenue !";
              }
            })
            .catch((err) => {
              this.dialog = false;
              this.loading = false;
              this.error = false;
              console.log(err);
            });

          if (!valid) {
            return;
          }
        }
        const dataForGenrating = {
          /*elec: {
            nom: "Mohamed",
            prenom: "Saber",
            email: "mohamed.saber@eneffic.fr",
            telephone: "0601020304",
            qualite: "Duhusijudf",
            raison: "BAROS DJKE",
            siret: "54515451451547",
            address: "rue ligue are 120225",
            date_signature: this.currentDateTime(),
            compteurs: [
              "54515451451547",
              "54515451451547",
              "54515451451547",
              "54515451451547",
              "54515451451547",
              "54515451451547",
              "54515451451547",
              "54515451451547",
            ],
          },*/
          /*gaz: {
            nom: "Mohamed",
            prenom: "Saber",
            sexe: "homme",
            fonction: "gérant",
            email: "mohamed.saber@eneffic.fr",
            telephone: "0601020304",
            qualite: "Duhusijudf",
            raison: "BAROS DJKE",
            siret: "54515451451547",
            address: "rue ligue are 34090",
            dateFinConsentement: "11/03/2024",
            dateToday: dayjs().format("DD/MM/YYYY"),
            date_signature: this.currentDateTime(),
            compteurs: [
              "54515451451547",
              "G025s0",
              "54515451451547",
              "54515451451547",
              "54515451451547",
              "G025s0",
              "54515451451547",
              "G025s0",
              "54515451451547",
              "54515451451547",
              "54515451451547",
              "G025s0",
              "54515451451547",
            ],
          },*/
          gaz: {
            nom: this.nomProps,
            prenom: this.prenomProps,
            sexe: "homme",
            fonction: this.fonctionProps,
            email: this.emailProps,
            telephone: this.numTelProps,
            raison: this.raisonProps,
            siret: this.siretProps,
            RCS: this.RCSProps,
            address: this.adressePostaleProps,
            dateFinConsentement: dayjs().add(1, "year").format("DD/MM/YYYY"),
            dateToday: dayjs().format("DD/MM/YYYY"),
            date_signature: this.currentDateTime(),
            compteurs: [...this.compteurGazProps],
          },
          elec: {
            nom: this.nomProps,
            prenom: this.prenomProps,
            email: this.emailProps,
            telephone: this.numTelProps,
            qualite: this.fonctionProps,
            raison: this.raisonProps,
            siret: this.siretProps,
            address: this.adressePostaleProps,
            date_signature: this.currentDateTime(),
            compteurs: [...this.compteurElecProps],
          },
        };
        let mergedPdf = await PDFDocument.create();
        if (this.acdGenerationType == "ELEC") {
          const pageELEC = await this.createPdfELEC(dataForGenrating.elec);
          mergedPdf = await this.addPagesToPdf(mergedPdf, pageELEC);
        } else if (this.acdGenerationType == "GAZ") {
          const pageGAZ = await this.createPdfGAZ(dataForGenrating.gaz);
          mergedPdf = await this.addPagesToPdf(mergedPdf, pageGAZ);
        } else {
          const pageELEC = await this.createPdfELEC(dataForGenrating.elec);
          const pageGAZ = await this.createPdfGAZ(dataForGenrating.gaz);
          mergedPdf = await this.addPagesToPdf(mergedPdf, pageELEC);
          mergedPdf = await this.addPagesToPdf(mergedPdf, pageGAZ);
        }

        // save pdf
        const filledPdfBytes = await mergedPdf.save();

        // Download the filled PDF
        if (filledPdfBytes) {
          const blob = new Blob([filledPdfBytes], { type: "application/pdf" });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "ACD.pdf";
          link.click();
        }
      }
    },
    // to merge pdf pages
    addPagesToPdf: async function (mergedPdf, pdfData) {
      const copiedPages = await mergedPdf.copyPages(pdfData, pdfData.getPageIndices());
      copiedPages.forEach((page) => {
        mergedPdf.addPage(page);
      });
      return mergedPdf;
    },
    async createPdfELEC(data) {
      // Load the PDF from the project folder
      const pdfPath = "/pdf/acd/acd-elec-volt.pdf";
      const response = await fetch(pdfPath);
      const arrayBuffer = await response.arrayBuffer();
      const pdfDoc = await PDFDocument.load(arrayBuffer);

      const timesRomanFont = await pdfDoc.embedFont(StandardFonts.TimesRoman);
      const timesRomanFontBold = await pdfDoc.embedFont(StandardFonts.TimesRomanBold);
      const pages = pdfDoc.getPages();
      let page = pages[0];
      const pageSize = page.getSize(); // {width: 595.25, height: 842}

      const fontSize = 11;
      const lineHeight = 1.9;
      const textColor = [0, 0, 0];
      const yTextLineHeightSpacer = fontSize * lineHeight;
      const xStart = 50;
      const firstPageTopSpace = 287;
      const blancPageTopSpace = 40;
      const blancPageBottomSpace = 40;
      let currentYPosition = pageSize.height - firstPageTopSpace;

      // footer config
      const fontSizeFooter = 9;
      const footerXPosition = pageSize.width / 2;
      const footerYPosition = pageSize.height - (pageSize.height - blancPageBottomSpace);
      const yFooterTextLineHeightSpacer = fontSizeFooter * 1.2;
      // const yTextLineHeightSpacer = timesRomanFont.heightAtSize(fontSize);
      // const yFooterTextLineHeightSpacer = timesRomanFont.heightAtSize(fontSizeFooter);

      page.drawText(`Je soussigné(e) `, {
        x: xStart,
        y: currentYPosition,
        size: fontSize,
        font: timesRomanFont,
        color: rgb(textColor[0], textColor[1], textColor[2]),
      });
      page.drawText(`${data.nom} ${data.prenom}`, {
        x: xStart + 70,
        y: currentYPosition,
        size: fontSize,
        font: timesRomanFontBold,
        color: rgb(textColor[0], textColor[1], textColor[2]),
      });

      currentYPosition = currentYPosition - yTextLineHeightSpacer; // move position down
      page.drawText(`Mail :`, {
        x: xStart,
        y: currentYPosition,
        size: fontSize,
        font: timesRomanFont,
        color: rgb(textColor[0], textColor[1], textColor[2]),
      });
      page.drawText(`${data.email}`, {
        x: xStart + 30,
        y: currentYPosition,
        size: fontSize,
        font: timesRomanFontBold,
        color: rgb(textColor[0], textColor[1], textColor[2]),
      });
      currentYPosition = currentYPosition - yTextLineHeightSpacer; // move position down
      page.drawText(`Numéro de téléphone :`, {
        x: xStart,
        y: currentYPosition,
        size: fontSize,
        font: timesRomanFont,
        color: rgb(textColor[0], textColor[1], textColor[2]),
      });
      page.drawText(`${this.formatPhoneNumber(data.telephone)}`, {
        x: xStart + 105,
        y: currentYPosition,
        size: fontSize,
        font: timesRomanFontBold,
        color: rgb(textColor[0], textColor[1], textColor[2]),
      });

      currentYPosition = currentYPosition - yTextLineHeightSpacer; // move position down
      page.drawText(`en ma qualité de`, {
        x: xStart,
        y: currentYPosition,
        size: fontSize,
        font: timesRomanFont,
        color: rgb(textColor[0], textColor[1], textColor[2]),
      });
      page.drawText(`${data.qualite}`, {
        x: xStart + 75,
        y: currentYPosition,
        size: fontSize,
        font: timesRomanFontBold,
        color: rgb(textColor[0], textColor[1], textColor[2]),
      });

      currentYPosition = currentYPosition - yTextLineHeightSpacer; // move position down
      page.drawText(`dûment habilité(e) à représenter la société`, {
        x: xStart,
        y: currentYPosition,
        size: fontSize,
        font: timesRomanFont,
        color: rgb(textColor[0], textColor[1], textColor[2]),
      });
      page.drawText(`${data.raison}`, {
        x: xStart + 190,
        y: currentYPosition,
        size: fontSize,
        font: timesRomanFontBold,
        color: rgb(textColor[0], textColor[1], textColor[2]),
      });

      currentYPosition = currentYPosition - yTextLineHeightSpacer; // move position down
      page.drawText(`Immatriculée sous le numéro SIRET`, {
        x: xStart,
        y: currentYPosition,
        size: fontSize,
        font: timesRomanFont,
        color: rgb(textColor[0], textColor[1], textColor[2]),
      });
      page.drawText(`${data.siret}`, {
        x: xStart + 170,
        y: currentYPosition,
        size: fontSize,
        font: timesRomanFontBold,
        color: rgb(textColor[0], textColor[1], textColor[2]),
      });

      currentYPosition = currentYPosition - yTextLineHeightSpacer; // move position down
      page.drawText(`dont le siège social est situé à`, {
        x: xStart,
        y: currentYPosition,
        size: fontSize,
        font: timesRomanFont,
        color: rgb(textColor[0], textColor[1], textColor[2]),
      });
      page.drawText(`${data.address}`, {
        x: xStart + 135,
        y: currentYPosition,
        size: fontSize,
        font: timesRomanFontBold,
        color: rgb(textColor[0], textColor[1], textColor[2]),
      });

      currentYPosition = currentYPosition - yTextLineHeightSpacer; // move position down
      const paragraphe1 =
        "donne mon accord express à la société VOLT CONSULTING et les fournisseurs d’énergie partenaires avec " +
        "lesquels celle-ci est liée par contrat pour recueillir auprès des gestionnaire de réseaux d’électricité, les données " +
        "techniques et contractuelles associées aux points de livraison de la société que je représente et " +
        "dont les références sont les suivantes:";
      const multiText = layoutMultilineText(paragraphe1, {
        alignment: "left",
        font: timesRomanFont,
        fontSize: fontSize,
        lineHeight: lineHeight,
        bounds: {
          width: pageSize.width - 100,
          height: pageSize.height,
        },
      });

      for (let i = 0; i < multiText.lines.length; i++) {
        page.drawText(`${multiText.lines[i].text}`, {
          x: xStart,
          y: currentYPosition,
          size: fontSize,
          maxWidth: pageSize.width - 100,
          color: rgb(textColor[0], textColor[1], textColor[2]),
          font: timesRomanFont,
        });
        // move position down
        currentYPosition = currentYPosition - yTextLineHeightSpacer;
      }

      // ********************************************************
      // ***************** compteurs list ***********************
      // ********************************************************
      const compteursText = data.compteurs.join(",   ");
      const compteursMultiText = layoutMultilineText(compteursText, {
        alignment: "left",
        font: timesRomanFont,
        fontSize: fontSize,
        lineHeight: lineHeight,
        bounds: {
          width: pageSize.width - 100,
          height: pageSize.height,
        },
      });

      for (let i = 0; i < compteursMultiText.lines.length; i++) {
        if (currentYPosition < footerYPosition + 75) {
          // add new Page
          page = pdfDoc.addPage();
          page.setFont(timesRomanFont);
          // reset starting position to the top of the new page
          currentYPosition = pageSize.height - blancPageTopSpace;
        }
        page.drawText(`${compteursMultiText.lines[i].text}`, {
          x: xStart,
          y: currentYPosition,
          size: fontSize,
          maxWidth: pageSize.width - 100,
          color: rgb(textColor[0], textColor[1], textColor[2]),
          font: timesRomanFont,
        });
        // move position down
        currentYPosition = currentYPosition - yTextLineHeightSpacer;
      }

      currentYPosition = currentYPosition - 10; // move position down
      if (currentYPosition < footerYPosition + 75) {
        // add new Page
        page = pdfDoc.addPage();
        page.setFont(timesRomanFont);
        // reset starting position to the top of the new page
        currentYPosition = pageSize.height - blancPageTopSpace;
      }
      page.drawText("en vu de la préparation d’une offre de fourniture d’électricité.", {
        x: xStart,
        y: currentYPosition,
        size: fontSize,
        font: timesRomanFont,
        color: rgb(textColor[0], textColor[1], textColor[2]),
      });

      currentYPosition = currentYPosition - (yTextLineHeightSpacer + 6); // move position down
      if (currentYPosition < footerYPosition + 75) {
        // add new Page
        page = pdfDoc.addPage();
        page.setFont(timesRomanFont);
        // reset starting position to the top of the new page
        currentYPosition = pageSize.height - blancPageTopSpace;
      }
      page.drawText(
        "La présente autorisation est conclue pour une durée de douze mois à compter de sa signature.",
        {
          x: xStart,
          y: currentYPosition,
          size: fontSize,
          font: timesRomanFont,
          color: rgb(textColor[0], textColor[1], textColor[2]),
        }
      );

      currentYPosition = currentYPosition - (yTextLineHeightSpacer + 6); // move position down
      // footerYPosition + 108 => text close to signature on 5 lines compteurs
      if (currentYPosition < footerYPosition + 108) {
        // add new Page
        page = pdfDoc.addPage();
        page.setFont(timesRomanFont);
        // reset starting position to the top of the new page
        currentYPosition = pageSize.height - blancPageTopSpace;
      }
      const paragraphe2 =
        "J’accepte expressément que mes données personnelles soient conservées à des fins de gestion et de " +
        "traçabilité, dans le respect du Règlement (UE) 2016/679 du 27 avril 2016 relatif à la protection des " +
        "personnes physiques à l’égard du traitement des données à caractère personnel et à la libre circulation de " +
        "ces données (RGPD).";
      const multiText2 = layoutMultilineText(paragraphe2, {
        alignment: "left",
        font: timesRomanFont,
        fontSize: fontSize,
        lineHeight: lineHeight,
        bounds: {
          width: pageSize.width - 100,
          height: pageSize.height,
        },
      });

      for (let i = 0; i < multiText2.lines.length; i++) {
        page.drawText(`${multiText2.lines[i].text}`, {
          x: xStart,
          y: currentYPosition,
          size: fontSize,
          maxWidth: pageSize.width - 100,
          color: rgb(textColor[0], textColor[1], textColor[2]),
          font: timesRomanFont,
        });
        // move position down
        currentYPosition = currentYPosition - yTextLineHeightSpacer;
      }

      // ********************************************************
      // ***************** Footer *******************************
      // ********************************************************
      /// draw signature and contact footer on every page
      const pagesToDrawFooterOn = pdfDoc.getPages();
      for (let i = 0; i < pagesToDrawFooterOn.length; i++) {
        const pageMissingFooter = pagesToDrawFooterOn[i];
        // signature
        this.addSignatureFooterELEC(
          pageMissingFooter,
          xStart,
          footerYPosition + 50,
          pageSize,
          fontSize,
          timesRomanFont,
          textColor,
          data.date_signature
        );
        // contact
        this.addContactFooterELEC(
          pageMissingFooter,
          footerXPosition,
          footerYPosition,
          yFooterTextLineHeightSpacer,
          fontSizeFooter,
          timesRomanFont,
          textColor
        );
      }

      return pdfDoc;
    },
    async createPdfGAZ(data) {
      console.log(data, "DATA");
      // Load the PDF from the project folder
      const pdfPath = "/pdf/acd/acd-gaz-volt-sans-bottom.pdf";
      const response = await fetch(pdfPath);
      const arrayBuffer = await response.arrayBuffer();
      const pdfDoc = await PDFDocument.load(arrayBuffer);

      const timesRomanFont = await pdfDoc.embedFont(StandardFonts.TimesRoman);
      const timesRomanFontBold = await pdfDoc.embedFont(StandardFonts.TimesRomanBold);
      const HelveticaFontBold = await pdfDoc.embedFont(StandardFonts.HelveticaBold);
      const pages = pdfDoc.getPages();
      let page = pages[0];
      const pageSize = page.getSize(); // {width: 595.25, height: 842}

      const fontSize = 11;
      const fontSizeFormTitle = 9;
      const fontSizeForm = 9;
      const lineHeight = 1.9;
      const textColor = [0, 0, 0];
      const yTextLineHeightSpacer = fontSize * lineHeight;
      const xStart = 65;
      const firstPageTopSpace = 120;
      const blancPageTopSpace = 40;
      const blancPageBottomSpace = 40;
      let currentYPosition = pageSize.height - firstPageTopSpace;

      // footer config
      const fontSizeFooter = 9;
      const footerXPosition = pageSize.width / 2;
      const footerYPosition = pageSize.height - (pageSize.height - blancPageBottomSpace);
      const yFooterTextLineHeightSpacer = fontSizeFooter * 1.2;
      // const yTextLineHeightSpacer = timesRomanFont.heightAtSize(fontSize);
      // const yFooterTextLineHeightSpacer = timesRomanFont.heightAtSize(fontSizeFooter);

      page.drawText(`Je soussigné :`, {
        x: xStart,
        y: currentYPosition,
        size: fontSize,
        font: timesRomanFont,
        color: rgb(textColor[0], textColor[1], textColor[2]),
      });
      // form 1
      // big rectangle
      page.drawRectangle({
        x: xStart - 20,
        y: currentYPosition - 140,
        width: pageSize.width - (xStart - 20) * 2,
        height: 130,
        rotate: degrees(0),
        borderWidth: 1,
        borderColor: rgb(0, 0, 0),
        color: rgb(1, 1, 1),
        opacity: 1,
        borderOpacity: 1,
      });
      // black small header rectangle
      page.drawRectangle({
        x: xStart - 20,
        y: currentYPosition - 20,
        width: pageSize.width - (xStart - 20) * 2,
        height: 12,
        rotate: degrees(0),
        borderWidth: 1,
        borderColor: rgb(0, 0, 0),
        color: rgb(0, 0, 0),
        opacity: 1,
        borderOpacity: 1,
      });
      // header form rectangle text
      page.drawText("A - CLIENT TITULAIRE DU CONTRAT DE FOURNITURE", {
        x: xStart - 10,
        y: currentYPosition - 17,
        size: fontSizeFormTitle,
        font: timesRomanFont,
        color: rgb(1, 1, 1),
      });
      // form 1 content
      currentYPosition = currentYPosition - 29; // move position down
      page.drawText("Type de structure / Forme juridique :", {
        x: xStart - 10,
        y: currentYPosition,
        size: fontSizeForm,
        font: timesRomanFont,
        color: rgb(textColor[0], textColor[1], textColor[2]),
      });
      currentYPosition = currentYPosition - 10; // move position down
      page.drawText(`Dénomination sociale :`, {
        x: xStart - 10,
        y: currentYPosition,
        size: fontSizeForm,
        font: timesRomanFont,
        color: rgb(textColor[0], textColor[1], textColor[2]),
      });
      page.drawText(`${data.raison}`, {
        x: xStart + 75,
        y: currentYPosition,
        size: fontSizeForm,
        font: timesRomanFontBold,
        color: rgb(textColor[0], textColor[1], textColor[2]),
      });

      currentYPosition = currentYPosition - 14; // move position down
      page.drawText(`N° d'identification (RCS ou SIRET) :`, {
        x: xStart - 10,
        y: currentYPosition,
        size: fontSizeForm,
        font: timesRomanFont,
        color: rgb(textColor[0], textColor[1], textColor[2]),
      });
      page.drawText(`${data.siret == null ? data.RCS : data.siret}`, {
        x: xStart + 128,
        y: currentYPosition,
        size: fontSizeForm,
        font: timesRomanFontBold,
        color: rgb(textColor[0], textColor[1], textColor[2]),
      });
      currentYPosition = currentYPosition - 9; // move position down
      page.drawText(`Adresse :`, {
        x: xStart - 10,
        y: currentYPosition,
        size: fontSizeForm,
        font: timesRomanFont,
        color: rgb(textColor[0], textColor[1], textColor[2]),
      });
      page.drawText(`${data.address}`, {
        x: xStart + 25,
        y: currentYPosition,
        size: fontSizeForm,
        font: timesRomanFontBold,
        color: rgb(textColor[0], textColor[1], textColor[2]),
      });
      currentYPosition = currentYPosition - 9; // move position down
      page.drawText(`Code Postal :`, {
        x: xStart - 10,
        y: currentYPosition,
        size: fontSizeForm,
        font: timesRomanFont,
        color: rgb(textColor[0], textColor[1], textColor[2]),
      });
      page.drawText(`${this.findCodePostal(data.address)}`, {
        x: xStart + 40,
        y: currentYPosition,
        size: fontSizeForm,
        font: timesRomanFontBold,
        color: rgb(textColor[0], textColor[1], textColor[2]),
      });
      currentYPosition = currentYPosition - 9; // move position down
      page.drawText("Identification du répresentant :", {
        x: xStart - 10,
        y: currentYPosition,
        size: fontSizeForm,
        font: timesRomanFontBold,
        color: rgb(textColor[0], textColor[1], textColor[2]),
      });
      page.drawLine({
        start: { x: xStart - 10, y: currentYPosition - 1.5 },
        end: { x: xStart - 10 + 120, y: currentYPosition - 1.5 },
        thickness: 0.5,
        color: rgb(0, 0, 0),
        opacity: 1,
      });
      currentYPosition = currentYPosition - 9; // move position down
      page.drawText(`Fonction :`, {
        x: xStart - 10,
        y: currentYPosition,
        size: fontSizeForm,
        font: timesRomanFont,
        color: rgb(textColor[0], textColor[1], textColor[2]),
      });
      page.drawText(`${data.fonction}`, {
        x: xStart + 30,
        y: currentYPosition,
        size: fontSizeForm,
        font: timesRomanFontBold,
        color: rgb(textColor[0], textColor[1], textColor[2]),
      });
      currentYPosition = currentYPosition - 9; // move position down
      page.drawText("M.", {
        x: xStart - 10,
        y: currentYPosition,
        size: fontSizeForm,
        font: timesRomanFont,
        color: rgb(textColor[0], textColor[1], textColor[2]),
      });
      page.drawRectangle({
        x: xStart - 10 + 15,
        y: currentYPosition,
        width: 7,
        height: 7,
        borderWidth: 1,
        borderColor: rgb(0, 0, 0),
        color: rgb(0, 0, 0),
        opacity: 0,
        borderOpacity: 0.75,
      });
      if (data.sexe == "homme") {
        page.drawLine({
          start: { x: xStart - 10 + 15, y: currentYPosition + 7 },
          end: { x: xStart - 10 + 15 + 7, y: currentYPosition },
          thickness: 0.5,
          color: rgb(0, 0, 0),
          opacity: 1,
        });

        page.drawLine({
          start: { x: xStart - 10 + 15 + 7, y: currentYPosition + 7 },
          end: { x: xStart - 10 + 15, y: currentYPosition },
          thickness: 0.5,
          color: rgb(0, 0, 0),
          opacity: 1,
        });
      }
      page.drawText("Mme", {
        x: xStart - 10 + 30,
        y: currentYPosition,
        size: fontSizeForm,
        font: timesRomanFont,
        color: rgb(textColor[0], textColor[1], textColor[2]),
      });
      page.drawRectangle({
        x: xStart - 10 + 30 + 25,
        y: currentYPosition,
        width: 7,
        height: 7,
        borderWidth: 1,
        borderColor: rgb(0, 0, 0),
        color: rgb(0, 0, 0),
        opacity: 0,
        borderOpacity: 0.75,
      });
      if (data.sexe == "femme") {
        page.drawLine({
          start: { x: xStart - 10 + 30 + 25, y: currentYPosition + 7 },
          end: { x: xStart - 10 + 30 + 25 + 7, y: currentYPosition },
          thickness: 0.5,
          color: rgb(0, 0, 0),
          opacity: 1,
        });

        page.drawLine({
          start: { x: xStart - 10 + 30 + 25 + 7, y: currentYPosition + 7 },
          end: { x: xStart - 10 + 30 + 25, y: currentYPosition },
          thickness: 0.5,
          color: rgb(0, 0, 0),
          opacity: 1,
        });
      }
      currentYPosition = currentYPosition - 10; // move position down
      page.drawText(`Nom :`, {
        x: xStart - 10,
        y: currentYPosition,
        size: fontSizeForm,
        font: timesRomanFont,
        color: rgb(textColor[0], textColor[1], textColor[2]),
      });
      page.drawText(`${data.nom}`, {
        x: xStart + 15,
        y: currentYPosition,
        size: fontSizeForm,
        font: timesRomanFontBold,
        color: rgb(textColor[0], textColor[1], textColor[2]),
      });
      currentYPosition = currentYPosition - 10; // move position down
      page.drawText(`Prénom :`, {
        x: xStart - 10,
        y: currentYPosition,
        size: fontSizeForm,
        font: timesRomanFont,
        color: rgb(textColor[0], textColor[1], textColor[2]),
      });
      page.drawText(`${data.prenom}`, {
        x: xStart + 25,
        y: currentYPosition,
        size: fontSizeForm,
        font: timesRomanFontBold,
        color: rgb(textColor[0], textColor[1], textColor[2]),
      });
      currentYPosition = currentYPosition - 10; // move position down
      page.drawText(`Téléphone :`, {
        x: xStart - 10,
        y: currentYPosition,
        size: fontSizeForm,
        font: timesRomanFont,
        color: rgb(textColor[0], textColor[1], textColor[2]),
      });
      page.drawText(`${this.formatPhoneNumber(data.telephone)}`, {
        x: xStart + 35,
        y: currentYPosition,
        size: fontSizeForm,
        font: timesRomanFontBold,
        color: rgb(textColor[0], textColor[1], textColor[2]),
      });
      currentYPosition = currentYPosition - 10; // move position down
      page.drawText(`Courriel :`, {
        x: xStart - 10,
        y: currentYPosition,
        size: fontSizeForm,
        font: timesRomanFont,
        color: rgb(textColor[0], textColor[1], textColor[2]),
      });
      page.drawText(`${data.email}`, {
        x: xStart + 27,
        y: currentYPosition,
        size: fontSizeForm,
        font: timesRomanFontBold,
        color: rgb(textColor[0], textColor[1], textColor[2]),
      });

      //RECTANGLE B (B - IDENTIFICATION DU TIERS (personne morale))
      page.drawRectangle({
        x: xStart - 20,
        y: currentYPosition - 120,
        width: pageSize.width - (xStart - 20) * 2,
        height: 100,
        rotate: degrees(0),
        borderWidth: 1,
        borderColor: rgb(0, 0, 0),
        color: rgb(1, 1, 1),
        opacity: 1,
        borderOpacity: 1,
      });
      // black small header rectangle
      page.drawRectangle({
        x: xStart - 20,
        y: currentYPosition - 30,
        width: pageSize.width - (xStart - 20) * 2,
        height: 12,
        rotate: degrees(0),
        borderWidth: 1,
        borderColor: rgb(0, 0, 0),
        color: rgb(0, 0, 0),
        opacity: 1,
        borderOpacity: 1,
      });
      page.drawText("B - IDENTIFICATION DU TIERS (personne morale)", {
        x: xStart - 10,
        y: currentYPosition - 27,
        size: fontSizeFormTitle,
        font: timesRomanFont,
        color: rgb(1, 1, 1),
      });
      currentYPosition = currentYPosition - 39; // move position down
      page.drawText("Type de structure / Forme juridique : SAS", {
        x: xStart - 10,
        y: currentYPosition,
        size: fontSizeForm,
        font: timesRomanFont,
        color: rgb(textColor[0], textColor[1], textColor[2]),
      });
      currentYPosition = currentYPosition - 10; // move position down
      page.drawText("Dénomination sociale : VOLT Consulting", {
        x: xStart - 10,
        y: currentYPosition,
        size: fontSizeForm,
        font: timesRomanFont,
        color: rgb(textColor[0], textColor[1], textColor[2]),
      });
      currentYPosition = currentYPosition - 10; // move position down
      page.drawText("N° d’identification (RCS ou SIRET) : 980 386726 RCS VERSAILLES", {
        x: xStart - 10,
        y: currentYPosition,
        size: fontSizeForm,
        font: timesRomanFont,
        color: rgb(textColor[0], textColor[1], textColor[2]),
      });
      currentYPosition = currentYPosition - 10; // move position down
      page.drawText("Adresse : 8 Place Hoche 78000 Versailles", {
        x: xStart - 10,
        y: currentYPosition,
        size: fontSizeForm,
        font: timesRomanFont,
        color: rgb(textColor[0], textColor[1], textColor[2]),
      });
      currentYPosition = currentYPosition - 10; // move position down
      page.drawText("Identification du répresentant :", {
        x: xStart - 10,
        y: currentYPosition,
        size: fontSizeForm,
        font: timesRomanFontBold,
        color: rgb(textColor[0], textColor[1], textColor[2]),
      });
      page.drawLine({
        start: { x: xStart - 10, y: currentYPosition - 1.5 },
        end: { x: xStart - 10 + 120, y: currentYPosition - 1.5 },
        thickness: 0.5,
        color: rgb(0, 0, 0),
        opacity: 1,
      });
      currentYPosition = currentYPosition - 12; // move position down
      page.drawText("M.", {
        x: xStart - 10,
        y: currentYPosition,
        size: fontSizeForm,
        font: timesRomanFont,
        color: rgb(textColor[0], textColor[1], textColor[2]),
      });
      page.drawRectangle({
        x: xStart - 10 + 15,
        y: currentYPosition,
        width: 7,
        height: 7,
        borderWidth: 1,
        borderColor: rgb(0, 0, 0),
        color: rgb(0, 0, 0),
        opacity: 0,
        borderOpacity: 0.75,
      });
      /* TEST LINE COCHER BUTTON
      page.drawLine({
        start: { x: xStart - 10 + 15, y: currentYPosition + 7 },
        end: { x: xStart - 10 + 15 + 7, y: currentYPosition },
        thickness: 0.5,
        color: rgb(0, 0, 0),
        opacity: 1,
      });

      page.drawLine({
        start: { x: xStart - 10 + 15 + 7, y: currentYPosition + 7 },
        end: { x: xStart - 10 + 15, y: currentYPosition },
        thickness: 0.5,
        color: rgb(0, 0, 0),
        opacity: 1,
      });*/
      page.drawText("Mme", {
        x: xStart - 10 + 30,
        y: currentYPosition,
        size: fontSizeForm,
        font: timesRomanFont,
        color: rgb(textColor[0], textColor[1], textColor[2]),
      });
      page.drawRectangle({
        x: xStart - 10 + 30 + 25,
        y: currentYPosition,
        width: 7,
        height: 7,
        borderWidth: 1,
        borderColor: rgb(0, 0, 0),
        color: rgb(0, 0, 0),
        opacity: 0,
        borderOpacity: 0.75,
      });
      page.drawLine({
        start: { x: xStart - 10 + 30 + 25, y: currentYPosition + 7 },
        end: { x: xStart - 10 + 30 + 25 + 7, y: currentYPosition },
        thickness: 0.5,
        color: rgb(0, 0, 0),
        opacity: 1,
      });

      page.drawLine({
        start: { x: xStart - 10 + 30 + 25 + 7, y: currentYPosition + 7 },
        end: { x: xStart - 10 + 30 + 25, y: currentYPosition },
        thickness: 0.5,
        color: rgb(0, 0, 0),
        opacity: 1,
      });
      currentYPosition = currentYPosition - 9; // move position down
      page.drawText("Nom : Decamme", {
        x: xStart - 10,
        y: currentYPosition,
        size: fontSizeForm,
        font: timesRomanFont,
        color: rgb(textColor[0], textColor[1], textColor[2]),
      });
      currentYPosition = currentYPosition - 9; // move position down
      page.drawText("Prénom : Paula", {
        x: xStart - 10,
        y: currentYPosition,
        size: fontSizeForm,
        font: timesRomanFont,
        color: rgb(textColor[0], textColor[1], textColor[2]),
      });
      currentYPosition = currentYPosition - 9; // move position down
      page.drawText("Courriel : pdecamme@volt-consulting.fr", {
        x: xStart - 10,
        y: currentYPosition,
        size: fontSizeForm,
        font: timesRomanFont,
        color: rgb(textColor[0], textColor[1], textColor[2]),
      });
      currentYPosition = currentYPosition - 25; // move position down
      const paragrapheGaz =
        "En signant ce document, vous déclarez être le titulaire du contrat de fourniture de gaz naturel du ou des PCE(s) mentionné(s) ci-\ndessous et vous autorisez GRDF, Société Anonyme au capital social de 1 800 745 000 euros, dont le siège social est situé 6, rue \n" +
        "Condorcet 75009 PARIS immatriculée au Registre du Commerce et des Sociétés de Parissous le numéro 444 786 511, à communiquer\n" +
        "directement au Tiers (C) ainsi qu’à ses fournisseurs partenaires, et le cas échéant ses représentants, les données suivantes via le\n" +
        "système d’informations de GRDF";
      const multiText = layoutMultilineText(paragrapheGaz, {
        alignment: "Right",
        font: timesRomanFont,
        fontSize: 9,
        lineHeight: 1,
        bounds: {
          width: pageSize.width - 30,
          height: pageSize.height,
        },
      });

      for (let i = 0; i < multiText.lines.length; i++) {
        page.drawText(`${multiText.lines[i].text}`, {
          x: xStart,
          y: currentYPosition,
          size: 9,
          color: rgb(textColor[0], textColor[1], textColor[2]),
          font: timesRomanFont,
        });
        // move position down
        currentYPosition = currentYPosition - 10;
      }
      currentYPosition = currentYPosition - 20; // move position down
      page.drawText("X", {
        x: xStart,
        y: currentYPosition,
        size: fontSizeForm,
        font: timesRomanFont,
        color: rgb(textColor[0], textColor[1], textColor[2]),
      });
      page.drawText("l’historique des consommations jusqu’à la date de ce consentement", {
        x: xStart + 10,
        y: currentYPosition,
        size: fontSizeForm,
        font: timesRomanFontBold,
        color: rgb(textColor[0], textColor[1], textColor[2]),
      });
      currentYPosition = currentYPosition - 15; // move position down
      page.drawText("X", {
        x: xStart,
        y: currentYPosition,
        size: fontSizeForm,
        font: timesRomanFont,
        color: rgb(textColor[0], textColor[1], textColor[2]),
      });
      page.drawText("le flux des consommations jusqu’à la date de fin du consentement", {
        x: xStart + 10,
        y: currentYPosition,
        size: fontSizeForm,
        font: timesRomanFontBold,
        color: rgb(textColor[0], textColor[1], textColor[2]),
      });
      currentYPosition = currentYPosition - 15; // move position down
      page.drawText("X", {
        x: xStart,
        y: currentYPosition,
        size: fontSizeForm,
        font: timesRomanFont,
        color: rgb(textColor[0], textColor[1], textColor[2]),
      });
      page.drawText("Les données contractuelles et techniques", {
        x: xStart + 10,
        y: currentYPosition,
        size: fontSizeForm,
        font: timesRomanFontBold,
        color: rgb(textColor[0], textColor[1], textColor[2]),
      });
      currentYPosition = currentYPosition - 15; // move position down
      page.drawText("X", {
        x: xStart,
        y: currentYPosition,
        size: fontSizeForm,
        font: timesRomanFont,
        color: rgb(textColor[0], textColor[1], textColor[2]),
      });
      page.drawText("Les données publiées", {
        x: xStart + 10,
        y: currentYPosition,
        size: fontSizeForm,
        font: timesRomanFontBold,
        color: rgb(textColor[0], textColor[1], textColor[2]),
      });
      currentYPosition = currentYPosition - 15; // move position down
      page.drawText("X", {
        x: xStart,
        y: currentYPosition,
        size: fontSizeForm,
        font: timesRomanFont,
        color: rgb(textColor[0], textColor[1], textColor[2]),
      });
      page.drawText("Les données informatives et de consommations journalières", {
        x: xStart + 10,
        y: currentYPosition,
        size: fontSizeForm,
        font: timesRomanFontBold,
        color: rgb(textColor[0], textColor[1], textColor[2]),
      });
      currentYPosition = currentYPosition - 30; // move position down
      let textTest =
        data.compteurs.length > 1 ? "N° PCE concerné(s) :" : "N° PCE concerné :";
      console.log(textTest.length);
      page.drawText(textTest, {
        x: xStart,
        y: currentYPosition,
        size: fontSizeForm,
        maxWidth: pageSize.width - 100,
        color: rgb(textColor[0], textColor[1], textColor[2]),
        font: timesRomanFontBold,
      });
      const compteursText = data.compteurs.join("   /   ");
      const compteursMultiText = layoutMultilineText(compteursText, {
        alignment: "left",
        font: timesRomanFontBold,
        fontSize: 9,
        lineHeight: 1,
        bounds: {
          width: pageSize.width - 100,
          height: pageSize.height,
        },
      });
      let xCompteurMultiTextLine = 0;
      console.log(currentYPosition, "POSITION BREAK PAGE");
      for (let i = 0; i < compteursMultiText.lines.length; i++) {
        console.log(i, xCompteurMultiTextLine);
        console.log(compteursMultiText.lines[i].text.split("/").length - 1);
        console.log(compteursMultiText.lines[i].text.length - 1);
        console.log(compteursMultiText.lines[i].text);
        xCompteurMultiTextLine += xStart + 90 + compteursMultiText.lines[i].width;
        page.drawText(`${compteursMultiText.lines[i].text}`, {
          x: xCompteurMultiTextLine >= pageSize.width - 50 ? xStart : xStart + 90,
          y:
            i == 0 && xCompteurMultiTextLine >= pageSize.width - 50
              ? currentYPosition - yTextLineHeightSpacer
              : currentYPosition,
          size: 9,
          maxWidth: pageSize.width - 100,
          color: rgb(textColor[0], textColor[1], textColor[2]),
          font: timesRomanFont,
        });

        // move position down
        if (i == 0 && xCompteurMultiTextLine >= pageSize.width - 150) {
          currentYPosition = currentYPosition - 20;
        }
        currentYPosition = currentYPosition - 15;
      }
      console.log(currentYPosition, "POSITION ");
      if (currentYPosition < 220) {
        // add new Page
        page = pdfDoc.addPage();
        page.setFont(timesRomanFont);
        // reset starting position to the top of the new page
        currentYPosition = pageSize.height - blancPageTopSpace;
      }
      page.drawText(`Date de fin du consentement : ${data.dateFinConsentement}`, {
        x: xStart,
        y: currentYPosition - 5,
        size: fontSizeForm,
        maxWidth: pageSize.width - 100,
        color: rgb(textColor[0], textColor[1], textColor[2]),
        font: timesRomanFontBold,
      });
      currentYPosition = currentYPosition - 29; // move position down
      const paragrapheGaz2 =
        "D’autre part, vous disposez d’un droit de révocation auprès du Tiers Autorisé ainsi que de ses fournisseurs partenaires ou de GRDF.\n" +
        "En particulier, GRDF met à votre disposition, à tout moment, la possibilité de consulter et/ou de révoquer l’ensemble des accès à vos\n" +
        "données via votre espace GRDF. Vous pouvez également retrouver l’ensemble de ces informations sur l’onglet « Données consultées\n" +
        "» de votre espace GRDF.";
      const multiText2 = layoutMultilineText(paragrapheGaz2, {
        alignment: "Right",
        font: timesRomanFont,
        fontSize: 9,
        lineHeight: 1,
        bounds: {
          width: pageSize.width - 30,
          height: pageSize.height,
        },
      });

      for (let i = 0; i < multiText2.lines.length; i++) {
        page.drawText(`${multiText2.lines[i].text}`, {
          x: xStart,
          y: currentYPosition,
          size: 9,
          color: rgb(textColor[0], textColor[1], textColor[2]),
          font: timesRomanFont,
        });
        // move position down
        currentYPosition = currentYPosition - 10;
      }
      currentYPosition = currentYPosition - 9; // move position down
      const paragrapheGaz3 =
        "Conformément aux dispositions de la loi n°78-17 du 6 janvier 1978 modifiée relative à l’informatique, aux fichiers et aux libertés, les\n" +
        "utilisateurs disposent d’un droit d’accès, de rectification et d’opposition sur les données personnelles les concernant. Il s’agit d’un\n" +
        "droit individuel ne pouvant être exercé que par la personne concernée par les données à caractère personnel. Pour exercer ce droit, \n" +
        "l’utilisateur doit écrire à GRDF Direction Juridique – Correspondant Informatique et Libertés – 6 Rue Condorcet\n" +
        "– 75009 PARIS CEDEX 09 ou protectiondesdonnees@grdf.fr";
      const multiText3 = layoutMultilineText(paragrapheGaz3, {
        alignment: "Right",
        font: timesRomanFont,
        fontSize: 9,
        lineHeight: 1,
        bounds: {
          width: pageSize.width - 30,
          height: pageSize.height,
        },
      });

      for (let i = 0; i < multiText3.lines.length; i++) {
        page.drawText(`${multiText3.lines[i].text}`, {
          x: xStart,
          y: currentYPosition,
          size: 9,
          color: rgb(textColor[0], textColor[1], textColor[2]),
          font: timesRomanFont,
        });
        // move position down
        currentYPosition = currentYPosition - 10;
      }
      /*let line = await this.getImage("/pdf/acd/bottom_pdf_gaz.png", pdfDoc);
      console.log(pageSize.width, "SIZE");
      page.drawImage(line, {
        // ATTENTION
        x: 0,
        y: 0,
        width: pageSize.width,
        height: 122,
      });
      page.drawText(`${data.raison}`, {
        x: xStart + 40,
        y: currentYPosition - 51,
        size: fontSize,
        maxWidth: pageSize.width - 100,
        color: rgb(textColor[0], textColor[1], textColor[2]),
        font: HelveticaFontBold,
      });
      page.drawText(`${data.dateToday}`, {
        x: xStart + 25,
        y: currentYPosition - 79,
        size: fontSize,
        maxWidth: pageSize.width - 100,
        color: rgb(textColor[0], textColor[1], textColor[2]),
        font: HelveticaFontBold,
      });*/
      currentYPosition = currentYPosition - 40; // move position down
      page.drawText(`Fait à : ${data.raison}`, {
        x: xStart,
        y: currentYPosition,
        size: fontSize,
        maxWidth: pageSize.width - 100,
        color: rgb(textColor[0], textColor[1], textColor[2]),
        font: HelveticaFontBold,
      });
      currentYPosition = currentYPosition - 25; // move position down
      page.drawText(`Le : ${data.dateToday}`, {
        x: xStart,
        y: currentYPosition,
        size: fontSize,
        maxWidth: pageSize.width - 100,
        color: rgb(textColor[0], textColor[1], textColor[2]),
        font: HelveticaFontBold,
      });
      page.drawRectangle({
        x: xStart + 230,
        y: currentYPosition - 30,
        width: 250,
        height: 75,
        rotate: degrees(0),
        borderWidth: 1,
        borderColor: rgb(0, 0, 0),
        color: rgb(1, 1, 1),
        opacity: 1,
        borderOpacity: 1,
      });
      // black small header rectangle
      console.log(xStart + 230 + currentYPosition + 30, "EGALE");
      page.drawRectangle({
        x: xStart + 230,
        y: currentYPosition + 30,
        width: 250,
        height: 30,
        rotate: degrees(0),
        borderWidth: 1,
        borderColor: rgb(0, 0, 0),
        color: rgb(0, 0, 0),
        opacity: 1,
        borderOpacity: 1,
      });
      let titreTexteRectangleSignature =
        "Signature du titulaire du contrat de founiture +\n" + "cachet le cas échéant";
      const multiText4 = layoutMultilineText(titreTexteRectangleSignature, {
        alignment: "Center",
        font: timesRomanFont,
        fontSize: 9,
        lineHeight: 1,
        bounds: {
          width: pageSize.width - 30,
          height: pageSize.height,
        },
      });

      for (let i = 0; i < multiText4.lines.length; i++) {
        page.drawText(`${multiText4.lines[i].text}`, {
          x:
            i == 0
              ? xStart + 230 + (200 - multiText4.lines[i].width) / 2
              : xStart + 230 + (230 - multiText4.lines[i].width) / 2,
          y: currentYPosition + 48,
          size: 12,
          color: rgb(1, 1, 1),
          font: timesRomanFont,
        });
        // move position down
        currentYPosition = currentYPosition - 15;
      }
      return pdfDoc;
    },
    addSignatureFooterELEC(
      page,
      x,
      y,
      pageSize,
      fontSize,
      fontFamily,
      textColor,
      date_signature
    ) {
      page.drawText(`Date: ${date_signature}`, {
        x: x,
        y: y,
        size: fontSize,
        font: fontFamily,
        color: rgb(textColor[0], textColor[1], textColor[2]),
      });
      let centerTextSign = this.setPageTextCenter(page, fontFamily, fontSize);
      centerTextSign("Signature et cachet:", pageSize.width / 2, y, textColor);
    },
    addContactFooterELEC(
      page,
      footerXPosition,
      footerYPosition,
      yFooterTextLineHeightSpacer,
      fontSize,
      fontFamily,
      textColor
    ) {
      let centerText = this.setPageTextCenter(page, fontFamily, fontSize);
      centerText(
        "VOLT CONSULTING - 8 Place Hoche 78000 Versailles - contact@volt-consulting.fr",
        footerXPosition,
        footerYPosition + yFooterTextLineHeightSpacer,
        textColor
      );
      centerText(
        "SIRET 980 386 726 RCS Versailles",
        footerXPosition,
        footerYPosition,
        textColor
      );
    },
    setPageTextCenter(page, font, size) {
      return (text, x, y, textColor) => {
        const widthText = font.widthOfTextAtSize(text, size);
        const props = {
          x: x - widthText / 2,
          y: y,
          size,
          font,
          color: textColor ? rgb(textColor[0], textColor[1], textColor[2]) : rgb(0, 0, 0),
        };
        page.drawText(text, props);
      };
    },
    // fonction qui crée la date du jour pour la mettre à la fin du fichier pdf "fait le"
    currentDateTime() {
      const current = new Date();
      const date =
        current.getDate() + "/" + (current.getMonth() + 1) + "/" + current.getFullYear();
      return date;
    },
  },
};
</script>

<style scoped>
::v-deep .v-text-field--outlined .v-label--active {
  font-family: "MontSerrat";
}
::v-deep .v-label {
  margin: 0px;
}
</style>
