<template>
  <div style="background-color: #eee">
     <loadingComponent @closeDialog='dialog = $event' :dialogProps="dialog" :messageDialogeProps="messageDialogeError" :loadingProps="loading" :errorProps="error" />
    <v-card style="overflow: hidden">
      <v-toolbar :color="$colors[0]" dark>
        <span class="text-h6 text-white">Création Excel Offre Ekwateur</span>
      </v-toolbar>
      <v-card-text class="my-6">
      <v-row>
        <v-col md="6">
            <v-radio-group  @change="resetData()" v-model="typeEnergie" row>
                <v-radio label="ELEC" value="ELEC"></v-radio>
                <v-radio label="GAZ" value="GAZ"></v-radio>
            </v-radio-group>
        </v-col>
        <v-col class="pb-0" cols="12" sm="6" md="6" lg="6" xl="6">
            <v-btn
                depressed
                elevation="1"
                large
                :color="$colors[0]"
                @click="submitContinue"
                class="mt-1 ma-auto text-white"
                width="100%"
            >
                Valider
            </v-btn>
        </v-col>
      </v-row>
        <v-form v-if="listCompteur.length > 0" ref="formAcd" lazy-validation>
          <v-row>
            <v-col md="12">
                <v-row>
                    <v-col md="6">
                        <v-autocomplete
                            dense
                            v-model="selectedCompteur"
                            :items="listCompteur"
                            :loading="isLoading"
                            hide-no-data
                            :rules="[rules.required]"
                            label="Compteur(s)"
                            placeholder="Ajouter un compteur"
                            prepend-icon="mdi-account-search-outline"
                            chips
                            item-text="numCompteur"
                            deletable-chips
                            multiple
                            return-object
                           >
                           <template v-slot:selection="{ item, index }">
                              <v-chip v-if="index === 0">
                                <span>{{ item.numCompteur }}</span>
                              </v-chip>
                              <span
                                v-if="index === 1"
                                class="grey--text text-caption"
                              >
                                (+{{ selectedCompteur.length - 1 }} {{selectedCompteur.length - 1 == 1 ? 'compteur' : 'compteurs'}})
                              </span>
                            </template>
                            <template v-slot:item="data">
                                <template>
                                    <v-list-item-content>
                                    <v-list-item-title>{{ data.item.numCompteur }}</v-list-item-title>
                                    <v-list-item-subtitle>{{
                                        data.item.commerciale
                                    }}</v-list-item-subtitle>
                                    </v-list-item-content>
                                </template>
                            </template>
                            <template v-slot:prepend-item>
                                <v-list-item ripple @click="toggle">
                                    <v-list-item-content>
                                    <v-list-item-title>Séléctionner tous les compteurs</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-divider class="mt-2"></v-divider>
                            </template>
                    </v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="12" md="6" lg="6" xl="6" v-if="selectedCompteur.length>0">
                        <v-btn class="text-white" :color="$colors[0]"  @click="appelleEnedis" >Récupérer la consommation</v-btn>
                    </v-col>
                    
                    <v-col cols="12" sm="12" md="12" lg="12" xl="12" v-if="listCompteurResult.length>1">
                        <v-form class="ma-4" ref="defaultForm" lazy-validation>
                        <v-row>
                            <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                            <strong>Appliqué comme valeurs par défaut pour toutes les ventes</strong>
                            </v-col>
                            <v-col cols="3">
                            <v-text-field dense type="date" outlined label="DDF par défaut" v-model="defaultDateDebut"></v-text-field>
                            </v-col>
                            <v-col cols="3">
                                <v-text-field dense outlined
                                    suffix="mois"
                                    label="durée par défaut" v-model="defaultduree"></v-text-field>
                            </v-col>
                            <v-col cols="3">
                                <v-text-field dense outlined  type="date" 
                                    label="DFF par défaut" v-model="defaultDateFin"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="3" md="2" lg="2" xl="2">
                            <v-btn @click="appliquesDefaultValue">appliquées</v-btn>
                            </v-col>
                        </v-row>
                        </v-form>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="12"
                        md="12"
                        lg="12"
                        xl="12"
                        v-if="listCompteurResult.length > 0 && typeEnergie == 'GAZ'"
                        >
                        <b-table
                            :per-page="perPageGaz"
                            :current-page="currentPageGaz"
                            id="table-multisite-gaz"
                            :busy="loadingTableHistorique"
                            :fields="fieldhistoriqueDeCotationGAZ"
                            :items="listCompteurResult"
                            responsive
                            bordered
                          >
                            <template #table-busy>
                                <div class="text-center text-danger my-2">
                                    <b-spinner class="align-middle"></b-spinner>
                                    <strong>Chargement...</strong>
                                </div>
                            </template>
                            <template #cell(numCompteur)="data">
                                <small>{{ data.item.numCompteur }}</small>
                            </template>
                            <template #cell(conso)="data">
                                <v-text-field
                                    ref="consogaz"
                                    dense
                                    outlined
                                    suffix="Mwh"
                                    type="number"
                                    step="0.01"
                                    :rules="[
                                    required('une consommation valide'),
                                    consoMaxMin(data.item.tarif),
                                    ]"
                                    v-model="data.item.conso"
                                >
                                </v-text-field>
                            </template>
                            <template #cell(tarif)="data">
                                <v-select
                                    required
                                    outlined
                                    @change="handleTarifChange"
                                    v-model="data.item.tarif"
                                    :items="['T1', 'T2', 'T3', 'T4']"
                                    hide-details="auto"
                                    dense
                                    :rules="[required('une Tarif valide'),rules.required(data.item.tarif)]"
                                ></v-select>
                            </template>
                            <template #cell(profile)="data">
                                <v-select
                                    v-if="data.item.tarif == 'T1'"
                                    required
                                    outlined
                                    v-model="data.item.profile"
                                    :items="listeProfileT1"
                                    hide-details="auto"
                                    dense
                                    :rules="[required('une Profil valide'),rules.required(data.item.profile)]"
                                ></v-select>
                                <v-select
                                    v-if="data.item.tarif == 'T2'"
                                    required
                                    outlined
                                    v-model="data.item.profile"
                                    :items="listeProfileT2"
                                    hide-details="auto"
                                    dense
                                    :rules="[required('une Profil valide'),rules.required(data.item.profile)]"
                                ></v-select>
                                <v-select
                                    v-if="data.item.tarif == 'T3' || data.item.tarif == 'T4'"
                                    required
                                    outlined
                                    v-model="data.item.profile"
                                    :items="listeProfileT3"
                                    hide-details="auto"
                                    dense
                                    :rules="[required('une Profil valide'),rules.required(data.item.profile)]"
                                ></v-select>
                            </template>
                            <template #cell(dateDebut)="data">
                                <v-text-field
                                    dense
                                    type="date"
                                    outlined
                                    :rules="[required('une DDF valide'),rules.required(data.item.profile)]"
                                    v-model="data.item.dateDebut"
                                ></v-text-field>
                            </template>
                            <template #cell(marge)="data">
                                <v-text-field
                                    dense
                                    type="date"
                                    outlined
                                    hide-details="auto"
                                    :rules="[required('une DDF valide'),rules.required(data.item.profile)]"
                                    v-model="data.item.dateRemiseOffre"
                                ></v-text-field>
                            </template>
                            <template #cell(dateFin)="data">
                                <v-text-field
                                    suffix="mois"
                                    dense
                                    outlined
                                    hide-details="auto"
                                    :rules="[required('un durée valide'),rules.required(data.item.profile)]"
                                    v-model="data.item.durree"
                                ></v-text-field>
                            </template>
                        </b-table>
                        <b-pagination
                            v-model="currentPageGaz"
                            :total-rows="rowsGaz"
                            :per-page="perPageGaz"
                        >
                        </b-pagination>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="12"
                        md="12"
                        lg="12"
                        xl="12"
                        v-if="listCompteurResult.length > 0 && typeEnergie == 'ELEC'"
                        >
                        <b-table
                            :per-page="perPage"
                            :current-page="currentPage"
                            id="tableau-multisite-elec"
                            class="text-center"
                            :busy="loadingTableHistorique"
                            :fields="fieldhistoriqueDeCotationELEC"
                            :items="listCompteurResult"
                            responsive
                            bordered
                          >
                            <template #table-busy>
                                <div class="text-center text-danger my-2">
                                    <b-spinner class="align-middle"></b-spinner>
                                    <strong>Chargement...</strong>
                                </div>
                            </template>
                            <template #cell(numCompteur)="data">
                                <small>{{ data.item.numCompteur }}</small>
                            </template>
                            <template #cell(conso)="data">
                                <v-text-field
                                    dense
                                    outlined
                                    suffix="Mwh"
                                    type="number"
                                    hide-details="auto"
                                    step="0.01"
                                    :rules="[required('une Consommation valide')]"
                                    disabled
                                    v-model="data.item.conso"
                                >
                                </v-text-field>
                            </template>
                            <template #cell(dateDebut)="data">
                                <v-text-field
                                    dense
                                    type="date"
                                    outlined
                                    hide-details="auto"
                                    :rules="[required('une DDF valide')]"
                                    v-model="data.item.dateDebut"
                                ></v-text-field>
                            </template>
                            <template #cell(marge)="data">
                                <v-text-field
                                    dense
                                    type="date"
                                    outlined
                                    hide-details="auto"
                                    :rules="[required('une DDF valide')]"
                                    v-model="data.item.dateRemiseOffre"
                                ></v-text-field>
                            </template>
                            <template #cell(dateFin)="data">
                                <v-text-field
                                    suffix="mois"
                                    dense
                                    outlined
                                    hide-details="auto"
                                    :rules="[required('un durée valide')]"
                                    v-model="data.item.durree"
                                ></v-text-field>
                            </template>
                            <template #cell(actions)="row">
                                <b-button  @click="row.toggleDetails">
                                    {{ row.detailsShowing ? "-" : "+" }}
                                </b-button>
                            </template>
                            <template #row-details="row">
                                <b-tr style="border: none !important">
                                    <b-td style="border: none !important">
                                        <v-select
                                            class="pa-0"
                                            v-model="row.item.optionTarifaire"
                                            @change="changeOptionTarifaire(row.item)"
                                            :items="optionsTarifaires"
                                            :rules="[required('Option Tarifaire'),rules.hasItemSelected(row.item.optionTarifaire)]"
                                            :disabled="row.item.techniqueData.result != 'notValide'"
                                            outlined
                                            dense
                                            hide-details
                                        />
                                    </b-td>
                                    <b-td
                                        style="border: none !important"
                                        v-if="
                                            row.item.optionTarifaire == 'BTINFMU4' ||
                                            row.item.optionTarifaire == 'BTINFCU4'
                                        "
                                        >
                                        <v-radio-group
                                            @change="
                                            changeOptionCalculeBtinf(row.item, row.item.parametreCompteur)
                                            "
                                            v-model="row.item.parametreCompteur"
                                            row
                                            class="smallradio"
                                        >
                                            <v-radio label="C4" value="C5C4"></v-radio>
                                            <v-radio label="HP/HC" value="C5HP"></v-radio>
                                            <v-radio label="Base" value="C5BASE"></v-radio>
                                        </v-radio-group>
                                    </b-td>
                                    <b-td  style="border: none !important; ">
                                        <v-text-field hide-details="auto" dense type="number" outlined label="KVA"
                                            :rules="[required('une KVA valide')]"
                                            v-model="row.item.kva"></v-text-field>
                                    </b-td>
                                    <b-td  style="border: none !important; ">
                                        <v-text-field hide-details="auto" dense type="number" outlined v-if="row.item.parametreCompteur == 'C5BASE'"
                                            :rules="[required('une CABASE valide')]" label="BASE (MWh)"
                                            @input="calculeSommeCotation(row.item)"
                                            v-model="row.item.CABASE"></v-text-field>
                                        <v-text-field hide-details="auto" dense type="number" outlined v-if="row.item.parametreCompteur == 'C5HP'"
                                            @input="calculeSommeCotation(row.item)" label="HP (MWh)"
                                            :rules="[required('une CAHP valide')]"
                                            v-model="row.item.CAHP"></v-text-field>
                                        <v-text-field hide-details="auto" dense type="number" outlined 
                                            v-if="row.item.parametreCompteur == 'C4' | 
                                            row.item.parametreCompteur == 'C3' |
                                            row.item.parametreCompteur == 'C5C4'"
                                            @input="calculeSommeCotation(row.item)"
                                            :rules="[required('une CHPH valide')]" label="HPH (MWh)"
                                            v-model="row.item.CAHPH"></v-text-field>
                                    </b-td>
                                    <b-td  style="border: none !important; "v-if="row.item.parametreCompteur != 'C5BASE'">
                                        <v-text-field hide-details="auto" dense type="number" outlined v-if="row.item.parametreCompteur == 'C5HP'"
                                            @input="calculeSommeCotation(row.item)" label="HC (MWh)"
                                            :rules="[required('une CAHC valide')]"
                                            v-model="row.item.CAHC"></v-text-field>
                                        <v-text-field hide-details="auto" dense type="number" outlined 
                                            v-if="row.item.parametreCompteur == 'C4' | 
                                            row.item.parametreCompteur == 'C3' |
                                            row.item.parametreCompteur == 'C5C4'"
                                            @input="calculeSommeCotation(row.item)"
                                            :rules="[required('une CAHCH valide')]" label="HCH (MWh)"
                                            v-model="row.item.CAHCH"></v-text-field>
                                    </b-td>
                                    <b-td  style="border: none !important; "v-if="row.item.parametreCompteur == 'C4' | 
                                        row.item.parametreCompteur == 'C3' |
                                        row.item.parametreCompteur == 'C5C4'">
                                        <v-text-field hide-details="auto" dense type="number" outlined 
                                            @input="calculeSommeCotation(row.item)"
                                            :rules="[required('une CAHPE valide')]" label="HPE (MWh)"
                                            v-model="row.item.CAHPE"></v-text-field>
                                    </b-td>
                                    <b-td  style="border: none !important; "v-if="row.item.parametreCompteur == 'C4' | 
                                        row.item.parametreCompteur == 'C3' |
                                        row.item.parametreCompteur == 'C5C4'">
                                        <v-text-field hide-details="auto" dense type="number" outlined 
                                            @input="calculeSommeCotation(row.item)"
                                            :rules="[required('une CAHCE valide')]" label="HCE (MWh)"
                                            v-model="row.item.CAHCE"></v-text-field>
                                    </b-td>
                                    <b-td  style="border: none !important; "v-if="row.item.parametreCompteur == 'C3'">
                                        <v-text-field hide-details="auto" dense type="number" outlined 
                                            :rules="[required('une CAPointe valide')]"
                                            @input="calculeSommeCotation(row.item)" label="Pointe (MWh)"
                                            v-model="row.item.CAP"></v-text-field>
                                    </b-td>
                                </b-tr>
                            </template>
                        </b-table>
                        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage">
                        </b-pagination>
                    </v-col>
                </v-row>
            </v-col>
          </v-row>        
          <div class="d-flex justify-content-end">
            <v-btn text color="error" class="mt-8 mr-4" @click="closeModal">
              Fermer
            </v-btn>
             <v-btn :disabled="selectedCompteur.length == 0 || listCompteurResult.length == 0" :color="$colors[0]"
              class="mt-8 mr-4 text-white" @click="generateExcel(typeEnergie)">Génération Excel</v-btn>
           
          </div>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import {
  degrees,
  PDFDocument,
  rgb,
  StandardFonts,
  TextAlignment,
  setFillingRgbColor,
  setFontAndSize,
  layoutMultilineText,
} from "pdf-lib";
import FileSaver from 'file-saver';
import download from "downloadjs";
import jsPDF from "jspdf";
import axios from "axios";
import loadingComponent from '@/components/ui/loading-component.vue'
import "jspdf-autotable";
export default {
  name: "ExcelEkwateur",
  props: { societe: "" },
  components:{
    loadingComponent
  },
  data() {
    return{
      messageDialogeError:"",
        success: false,
        listCompteur: [],
        openModalAcdEngie: true,
        loadingTableHistorique: false,
        fieldhistoriqueDeCotationELEC:[
            {key:"numCompteur", label:"Compteur",tdClass:'tdCenter'},
            {key:"conso",label:"Consommation"},
            {key:"dateDebut", label:"Début de contrat"},
            {key: "dateFin", label:"Durée"},
            {key:"marge", label:"Date Remise Offre"},
            {key:"actions", label:"Détails"},
            
        ],
        fieldhistoriqueDeCotationGAZ:[
            {key:"numCompteur", label:"Compteur",tdClass:'tdCenter'},
            {key:"conso",label:"Consommation"},
            {key:"tarif",label:"tarif"},
            {key:"profile",label:"profil"},
            {key:"dateDebut", label:"Début de debut"},
            {key: "dateFin", label:"Durée"},
            {key:"marge", label:"Date Remise Offre"},
        ],
        listeProfileT1: ["P011"],
        listeProfileT2: ["P012", "P013", "P014", "P015", "P016", "P017", "P018", "P019"],
        listeProfileT3: ["P013", "P014", "P015", "P016", "P017", "P018", "P019"],
        listCompteurResult: [],
        perPage: 3,
        currentPage: 1,
        perPageGaz: 3,
        currentPageGaz: 1,
        error_enedis: true,
        dialog: false,
        loading: false,
        error: false,
        isLoading: false,
        filledData:{
            commentaires:''
        },
        typeEnergie: "ELEC",
        optionsTarifaires: [
            "BTINFCU4",
            "BTINFCUST",
            "BTINFMU4",
            "BTINFMUDT",
            "BTINFLU",
            "BTSUPCU4",
            "BTSUPLU4",
            "HTACU5",
            "HTACUPM5",
            "HTALU5",
            "HTALUPM5",
        ],
        selectedCompteur: [],
        defaultDateDebut: "",
        defaultDateFin: "",
        defaultduree: "",
        rules: {
            required: value => (!!value && (""+value).length>0)|| 'Ce champ est obligatoire.',
            hasItemSelected: value => value.length >=1 || 'Vous devez séléctionner une valeur',
            requiredString: value => (""+value).length>8|| 'Ce champ est obligatoire.',
            siretLength: v => v.length == 14 || 'Le siret doit comporter 14 chiffres',
            isNumber: v => v.match(/^[0-9]+$/) != null || "Ce champ n'accepte que les chiffres",
            compteurElecLength: v => v.length == 14 || 'Le RAE / PDL est une suite de 14 chiffres',
            isGazCompteur: v => (v.match(/^(GI)[0-9]{6}$/) || (v.length == 14 && v.match(/^[0-9]+$/) != null)) || 'Le numéro PCE/ PDL doit commencer par (GI) ou comporter 14 chiffres',
            phoneLength: v => v.length == 10 || 'Le numéro de téléphone est une suite de 10 chiffres',
            phoneisNumber: v => v.match(/^[0-9]+$/) != null || "Ce champ n'accepte que les chiffres",
            margeMaxMin: v => (v<=25 && v>=2) || 'La marge doit être comprise entre 2 et 25',
            
            emailRules: [
            v => !!v || 'E-mail obligatoire',
            v => /.+@.+\.+.+/.test(v) || "l'adresse mail est invalide",
            ],
        },
        required(fieldName) {
            return (value) => !!value || value>=0  || `Veuillez saisir ${fieldName}`
        },
        consoMaxMin(typeCompteur) {
            return (v) => {
            if (typeCompteur == "T1") {
                if (v >= 0 && v < 6) {
                return true;
                } else {
                return "consomation pour un Tarif T1 doit être comprise entre 0 et 5.99";
                }
            } else if (typeCompteur == "T2") {
                if (v >= 3 && v <= 350) {
                return true;
                } else {
                return "consomation pour un Tarif T2 doit être comprise entre 3 et 350";
                }
            } else if (typeCompteur == "T3") {
                if (v >= 100 && v < 5000) {
                return true;
                } else {
                //250 4999.99
                return "consomation pour un Tarif T3 doit être comprise entre 100 et 4999.99";
                }
            } else if (typeCompteur == "T4") {
                if (v >= 5000) {
                return true;
                } else {
                return "consomation pour un Tarif T4 doit être supérieur ou égal à 5000";
                }
            }
            };
        },
    }
  },
    computed: {
      rows() {
          return this.listCompteurResult.length
      },
      rowsGaz() {
          return this.listCompteurResult.length
      }
    },
    watch: {
      selectedCompteur(newVal, oldVal){
          if(newVal.length != oldVal.length){
              var index = -1
              for (var i = 0; this.listCompteurResult.length; i++){
                  index = newVal.findIndex(x => x === this.listCompteurResult[i].numCompteur)
                  if (index == -1) { 
                      this.listCompteurResult.splice(i, 1);
                      break
                  }
              }
          }
      },
      listCompteurResult(val){
          for(var i=0; i<this.listCompteurResult.length; i++){
              this.listCompteurResult[i]['valeur'] = 0
          }
      }
    },
  methods: {
    resetData() {
      this.listCompteur = [];
      this.listCompteurResult = [];
      this.selectedCompteur = "";
    },
    appliquesDefaultValue() {
      if (this.$refs.defaultForm.validate()) {
        for (var i = 0; i < this.listCompteurResult.length; i++) {
          if (this.defaultDateDebut != "" && this.defaultDateDebut != null) {
            this.listCompteurResult[i]["dateDebut"] = this.defaultDateDebut;
          }
          if (this.defaultduree != "" && this.defaultduree != null) {
            this.listCompteurResult[i]["durree"] = this.defaultduree;
          }
          if (this.defaultDateFin != "" && this.defaultDateFin != null) {
            this.listCompteurResult[i]["dateRemiseOffre"] = this.defaultDateFin;
          }
        }
      }
    },
    async appelleEnedis(){
                this.loadingTableHistorique = true;
                this.titleDialog = "Recupération de la consommation"
                await this.getConsoAllCompteur(this.typeEnergie)
                this.loadingTableHistorique = false;
                this.dialog = false;
                this.error = false;
            },  
    async generateExcel(typeEnergie){
      if(this.$refs.formAcd.validate()){
          var formData = new FormData();
          formData.append("token", this.getToken("token"));
          formData.append("typeDemande", typeEnergie);
          formData.append("societe", JSON.stringify(this.societe));
          formData.append('listeToutesVente',JSON.stringify(this.listCompteurResult));
          await axios({
          url: process.env.VUE_APP_URL_API_CLIENT + "getExcelEkwateur/",
          method: "POST",
          data: formData,
        }).then((res)=>{
          console.log(res)
          if(res.status == 200){
            // var url = res.data.file_xlsx;
            // const link = document.createElement("a");
            // link.href = url;
            // link.download = "test.xlsx";
            // link.click();

            console.log(res.data.file_xlsx)
            const binaryString = window.atob(res.data.file_xlsx);
            const binaryLen = binaryString.length;
            const bytes = new Uint8Array(binaryLen);

            for (let i = 0; i < binaryLen; i++) {
              bytes[i] = binaryString.charCodeAt(i);
            }
            console.log(bytes)
            const blob = new Blob([bytes], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = `Excel_Ekwateur_${this.societe.Raison}.xlsx`;

            // Append the link to the body
            document.body.appendChild(link);

            // Programmatically click the link to trigger the download
            link.click();

            // Remove the link from the document
            document.body.removeChild(link);
          }
        })
      }
    },
    async submitContinue() {
                    this.dialog = true;
                    this.loading = true;
                    this.typeEnergieLocked = true
                    await this.getInfoEtCompteur(
                    this.societe.siret,
                    this.typeEnergie
                    )
            },
            async getInfoEtCompteur(siret, typeEnergie) {  
                let self = this
                this.success = true
                var formData = new FormData()
                formData.append('siret', siret)
                formData.append('token', this.getToken('token'))
                formData.append('type', typeEnergie)
                await axios({
                    url:
                        process.env.VUE_APP_URL_API_CLIENT + 'getInfoEtCompteurVentePro/',
                    method: 'POST',
                    data: formData,
                }).then((res)=>{
                    var data = JSON.parse(JSON.stringify(res.data))
                    if(data.result == false){
                        this.success = false
                        this.loading = false;
                        this.error = true;
                        this.messageDialogeError = "Il n'y a pas de numéro de compteur associé à ce siret, merci de vérifier que votre demande de cotation a bien été acceptée"
                    }else if(data.result == true){
                        this.success = true
                        this.listCompteur = data.compteur
                        this.listCompteur = data.compteur.map(compteur => ({
                            ...compteur,
                            dateRemiseOffre: ""
                        }));
                      this.loading = false;
                      this.dialog = false;
                    }else if(data.result == 'permissionAdmin'){
                        this.success = false
                        this.messageDialogeError = "Il n'y a pas de numéro de compteur associé à ce siret, merci de vérifier que votre Demande de Cotation a bien été accepté"
                    
                    }else if(data.result == 'permission'){
                        localStorage.removeItem('vendeurName')
                        localStorage.removeItem('typeUser')
                        localStorage.removeItem('token')
                        this.$router.push('/login')
                    }
                    
                }).catch ((err) => {
                    self.success = false
                })
                
            },
    async getConsoAllCompteur(typeEnergie) {
                if (typeEnergie == "GAZ") {
                    this.dialog = true;
                    this.loading = true;
                    this.title =
                    "veuillez patienter jusqu'à ce que nous avons obtenu les informations relatives aux numéros des compteurs!";
                    this.messageDialoge =
                    "veuillez patienter jusqu'à ce que nous ayons obtenu les informations relatives aux numéros des compteurs!";
                    var formData = new FormData();
                    formData.append("token", this.getToken("token"));
                    formData.append("listCompteur", JSON.stringify(this.selectedCompteur));
                    formData.append("dateDebut", "01/01/2024");
                    formData.append("dateFin", "31/12/2026");
                    formData.append("siret", this.societe.siret);
                    await axios({
                    url: process.env.VUE_APP_URL_API_CLIENT + "getAllCompteurConsoGaz/",
                    method: "POST",
                    data: formData,
                    })
                    .then((res) => {
                        if (res.data.result == false) {
                        this.listCompteurResult = [];
                        this.consommationRecuperer = false;
                        this.error = true;
                        this.messageDialogeError =
                            "nous n'arrivons pas à obtenir les informations relatives au Siret!";
                        } else {
                            this.listCompteurResult = res.data.result;
                            if(this.listCompteurResult[0].numCompteur == 'undefined'){
                                this.listCompteurResult[0].numCompteur = this.selectedCompteur[0].numCompteur;
                            }
                            this.consommationRecuperer = true;
                            this.dialog = false;
                        this.listCompteurResult = res.data.result;
                        console.log(this.listCompteurResult)
                        if(this.listCompteurResult[0].numCompteur == 'undefined'){
                            this.listCompteurResult[0].numCompteur = this.selectedCompteur[0].numCompteur;
                        }
                        this.consommationRecuperer = true;
                        this.dialog = false;
                        }
                    })
                    .catch((err) => {
                        this.consommationRecuperer = false;
                        this.listCompteurResult = [];
                        this.error = true;
                        this.messageDialogeError =
                        "nous n'arrivons pas à obtenir les informations relatives à tous les numéros de compteur présents dans votre Demande de cotation!";
                    });
                } else if (typeEnergie == "ELEC") {
                    this.dialog = true;
                    this.loading = true;
                    this.title =
                    "veuillez patienter jusqu'à ce que nous avons obtenu les informations relatives aux numéros des compteurs!";
                    this.messageDialoge =
                    "veuillez patienter jusqu'à ce que nous ayons obtenu les informations relatives aux numéros des compteurs!";
                    var formData = new FormData();
                    formData.append("token", this.getToken("token"));
                    formData.append("listCompteur", JSON.stringify(this.selectedCompteur));
                    formData.append("dateDebut", "01/01/2024");
                    formData.append("dateFin", "31/12/2026");
                    formData.append("siret", this.societe.siret);
                    await axios({
                    url: process.env.VUE_APP_URL_API_CLIENT + "getAllCompteurConso/",
                    method: "POST",
                    data: formData,
                    })
                    .then((res) => {
                        if (res.data.result == false) {
                        this.listCompteurResult = [];
                        this.consommationRecuperer = false;
                        this.error = true;
                        this.loading = false;
                        this.messageDialogeError =
                            "nous n'arrivons pas à obtenir les informations relatives au Siret!";
                        } else {
                            this.listCompteurResult = res.data.result;
                            if(this.listCompteurResult[0].numCompteur == 'undefined'){
                                this.listCompteurResult[0].numCompteur = this.selectedCompteur[0].numCompteur;
                            }
                            this.consommationRecuperer = true;
                            this.dialog = false;
                        }
                    })
                    .catch((err) => {
                        this.listCompteurResult = [];
                        this.consommationRecuperer = false;
                        this.error = true;
                        this.loading = false;
                        this.messageDialogeError =
                        "nous n'arrivons pas à obtenir les informations relatives à tous les numéros de compteur présents dans votre Demande de cotation!";
                    });
                }
            },
    closeModal() {
      this.$emit("closeMandatModal");
    },
    toggle() {
      this.selectedCompteur = [...this.listCompteur];
    },
    formatDate(dateStr) {
      const regex = /^\d{4}-\d{2}-\d{2}$/;
      if (!regex.test(dateStr)) {
        throw new Error('Le format de la date doit être "année-mois-jour"');
      }

      const [year, month, day] = dateStr.split("-");

      return `${day}/${month}/${year}`;
    },
    async drawTextWithBoldWords(
      page,
      text,
      wordsToFormat,
      x,
      y,
      fontSize,
      maxWidth,
      color
    ) {
      // Load the font for regular text
      const regularFont = await page.doc.embedFont(StandardFonts.Helvetica);
      // Load the font for bold text
      const boldFont = await page.doc.embedFont(StandardFonts.HelveticaBold);

      // Create a regex pattern to match any of the words to format
      const regexPattern = new RegExp(
        `(${wordsToFormat.map((word) => word.text).join("|")})`,
        "gi"
      );

      // Split the text into parts: words to format and other text
      const parts = text.split(regexPattern);

      let currentX = x;
      for (const part of parts) {
        const format = wordsToFormat.find(
          (word) => word.text.toLowerCase() === part.toLowerCase()
        );
        const isBold = format !== undefined;
        const font = isBold ? boldFont : regularFont;
        const width = font.widthOfTextAtSize(part, fontSize);

        page.drawText(part, {
          x: currentX,
          y,
          size: fontSize,
          maxWidth,
          font,
          color,
        });

        // If the part is a word to be formatted and should be underlined, draw the underline
        if (isBold && format.underline) {
          page.drawLine({
            start: { x: currentX, y: y - 2 }, // Adjust y position for underline
            end: { x: currentX + width, y: y - 2 },
            thickness: 0.5,
            color: color,
          });
        }

        currentX += width;
      }
    },
    async createMandatResiliation() {
      /////////////// page 1 ///////////////////////////////////////
      var url0 = "/pdf/resiliation/mandat_resiliation_1.pdf";
      var pageZero = await fetch(url0).then((res) => res.arrayBuffer());
      // Load a PDFDocument from each of the existing PDFs
      var pageZeroDoc = await PDFDocument.load(pageZero);

      var getPages = pageZeroDoc.getPages();
      var firstPage = getPages[0];
      var form = pageZeroDoc.getForm();
      var field = form.getTextField("textarea_societe");
      var nPointLivraison = "";
      for (var i = 0; i < this.selectedCompteur.length; i++) {
        nPointLivraison =
          nPointLivraison + this.selectedCompteur[i]["numCompteur"] + ", ";
      }
      var txt =
        `La société : ${this.societe.Raison}\n` +
        `enregistrée sous le numéro SIREN : ${this.societe.siren}\n` +
        `dont le siège social est situé à : ${this.societe.AdressePostal}\n` +
        `dont le numéro de compteur est : ${nPointLivraison}\n` +
        `représentée par : ${this.societe.nom} ${this.societe.prenom}\n` +
        "dument habilité(e) à cet effet, ci-après désigné(e) par « Le Mandant » d’une part et,\n";
      const textLines = txt.split("\n");
      const lineHeight = 15; // Adjust this value to set the line spacing
      const pageWidth = firstPage.getWidth();
      const pageHeight = firstPage.getHeight();
      let startingPositon = pageHeight - 240; // Adjust the starting Y coordinate
      const timesRomanFont = await pageZeroDoc.embedFont(StandardFonts.HelveticaBold);
      var multiText = layoutMultilineText(txt, {
        alignment: "left",
        fontSize: 12,
        font: timesRomanFont,
        lineHeight: lineHeight,
        bounds: { width: pageWidth - 80, height: pageHeight },
      });
      let listeMotGras = [
        { text: "La société", underline: true },
        { text: "SIREN", underline: true },
        { text: "siège social", underline: true },
        { text: "numéro de compteur", underline: true },
        { text: "représentée par", underline: true },
        { text: `${nPointLivraison}`, underline: false },
      ];
      for (let i = 0; i < multiText.lines.length; i++) {
        if (startingPositon - lineHeight < 30) {
          var firstPage = pageZeroDoc.addPage([pageWidth, pageHeight]);
          var newPageFirstTextY = pageHeight - 50;
          startingPositon = newPageFirstTextY - lineHeight; // Reset the Y coordinate for the new page
          firstPage.drawText(`${multiText.lines[i].text}`, {
            x: 65,
            y: startingPositon,
            size: 12,
            maxWidth: pageWidth - 80,
            color: rgb(0, 0, 0),
          });
        } else {
          await this.drawTextWithBoldWords(
            firstPage,
            multiText.lines[i].text,
            listeMotGras,
            58,
            startingPositon,
            11,
            pageWidth - 80,
            rgb(0, 0, 0)
          );
        }
        // move position down
        startingPositon = startingPositon - lineHeight;
      }
      var field = form.getTextField("textarea_mandat");
      var textPT1 =
        `Par le présent mandat spécial, le Mandant donne pouvoir au Mandataire, et à lui seul,\n` +
        `d’effectuer, en son nom et pour son compte, les démarches nécessaires auprès de son\n` +
        `fournisseur actuel : ${this.dataFournisseur.nomFournisseur} pour résilier son contrat à la date d’échéance de\n` +
        `celui-ci soit le : ${this.dateFormatSlach(this.dataFournisseur.dateResiliation)},\n`;
      var textPT2 =
        `J’atteste avoir vérifier en amont la date de fin de contrat au dos de la facture ou le cas\n` +
        `échéant sur le contrat. Je désengage de toute responsabilité le fournisseur ${this.dataFournisseur.nomFournisseur}\n` +
        `et le courtier Volt Consulting en ce qui concerne les frais de pénalité en cas de non-\nrespect du préavis de résiliation.`;

      const textLinesFinalPT1 = textPT1.split("\n");
      console.log(textLinesFinalPT1);
      const lineHeightFinal = 10; // Adjust this value to set the line spacing
      const pageWidthFinal = firstPage.getWidth();
      const pageHeightFinal = firstPage.getHeight();
      let startingPositionFinal = pageHeightFinal - 470; // Adjust the starting Y coordinate
      var multiTextPT1 = layoutMultilineText(textPT1, {
        alignment: "left",
        fontSize: 12,
        font: timesRomanFont,
        lineHeight: lineHeightFinal,
        bounds: { width: pageWidth - 80, height: lineHeightFinal },
      });
      let listeMotGrasPT1 = [
        { text: "fournisseur actuel", underline: true, sameLine: true },
        { text: "date d'échéance", underline: true, sameLine: true },
      ];
      for (let i = 0; i < multiTextPT1.lines.length; i++) {
        if (startingPositionFinal - lineHeight < 30) {
          var firstPage = pageZeroDoc.addPage([pageWidthFinal, pageHeightFinal]);
          var newPageFirstTextY = pageHeightFinal - 50;
          startingPositionFinal = newPageFirstTextY - lineHeight; // Reset the Y coordinate for the new page
          firstPage.drawText(`${multiTextPT1.lines[i].text}`, {
            x: 65,
            y: startingPositionFinal,
            size: 11.2,
            maxWidth: pageWidthFinal - 80,
            color: rgb(0, 0, 0),
          });
        } else {
          await this.drawTextWithBoldWords(
            firstPage,
            multiTextPT1.lines[i].text,
            listeMotGrasPT1,
            58,
            startingPositionFinal,
            12,
            pageWidth - 80,
            rgb(0, 0, 0)
          );
        }
        // move position down
        startingPositionFinal = startingPositionFinal - lineHeight;
      }

      const textLinesFinalPT2 = textPT2.split("\n");
      console.log(textLinesFinalPT2);

      startingPositionFinal = startingPositionFinal + 10; // Adjust the starting Y coordinate
      var multiTextPT2 = layoutMultilineText(textPT2, {
        alignment: "left",
        fontSize: 12,
        font: timesRomanFont,
        lineHeight: lineHeightFinal,
        bounds: { width: pageWidth - 80, height: pageHeight },
      });
      let listeMotGrasPT2 = [{ text: "le fournisseur", underline: true }];
      for (let i = 0; i < multiTextPT2.lines.length; i++) {
        if (startingPositionFinal - lineHeight < 30) {
          var firstPage = pageZeroDoc.addPage([pageWidthFinal, pageHeightFinal]);
          var newPageFirstTextY = pageHeightFinal - 50;
          startingPositionFinal = newPageFirstTextY - lineHeight; // Reset the Y coordinate for the new page
          firstPage.drawText(`${multiTextPT2.lines[i].text}`, {
            x: 65,
            y: startingPositionFinal,
            size: 11.2,
            maxWidth: pageWidthFinal - 80,
            color: rgb(0, 0, 0),
          });
        } else {
          await this.drawTextWithBoldWords(
            firstPage,
            multiTextPT2.lines[i].text,
            listeMotGrasPT2,
            58,
            startingPositionFinal,
            12,
            pageWidth - 80,
            rgb(0, 0, 0)
          );
        }
        // move position down
        startingPositionFinal = startingPositionFinal - lineHeight;
      }

      var field = form.getTextField("text_date");
      var DateAujourdhui = new Date();
      DateAujourdhui =
        DateAujourdhui.getDate() +
        "/" +
        (DateAujourdhui.getMonth() + 1) +
        "/" +
        DateAujourdhui.getFullYear();
      field.setText(DateAujourdhui);
      field.setFontSize(12);
      var fields = form.getFields();
      fields.forEach((field) => {
        field.enableReadOnly();
      });
      form.updateFieldAppearances();
      let mergedPdf = await PDFDocument.create();
      mergedPdf = await this.addPagesToPdf(mergedPdf, pageZeroDoc);

      const pdfBytes = await mergedPdf.save();
      this.generatedPdf = pdfBytes;
      download(pdfBytes, "Mandat_résiliation.pdf", "application/pdf");
    },
    dateFormatSlach(date) {
      try {
        return date.split("-").reverse().join("/");
      } catch (err) {
        return date;
      }
    },
    async addPagesToPdf(mergedPdf, donorPdf) {
      const copiedPages = await mergedPdf.copyPages(donorPdf, donorPdf.getPageIndices());
      copiedPages.forEach((page) => {
        mergedPdf.addPage(page);
      });
      return mergedPdf;
    },
  },
};
</script>
<style>
#table-multisite-gaz.table-bordered td:first-child,
#tableau-multisite-elec.table-bordered td:first-child {
  vertical-align: middle !important;
}
.tdCenter{
        vertical-align:middle;
    }
  #tableau-multisite-elec.table thead th, #table-multisite-gaz.table thead th{
    background-color:#103A5E;
    color:white;
    font-weight:bold;
  }
</style>
<style scoped>
::v-deep .v-label{
  margin:0px; 
}

@media only screen and (max-width:400px){
    .title-container{
      font-size:large;
      margin-left:0px !important;
    }
  }
</style>
<style scoped lang="scss">
    .dropzone {
    border: 2px solid #90a4ae56;
    border-radius: 8px;
    min-height: 5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &:hover {
        cursor: pointer;
    }
    p {
        margin-bottom: 0;
        &:first-of-type {
        font-weight: 500;
        font-size: 1rem;
        color: #263238;
        span {
            color: gray;
        }
        }
        &:last-of-type {
        font-size: 0.8rem;
        }
    }
    }
    .requireddropzone {
    border: 2px dashed red;
    border-radius: 8px;
    min-height: 5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &:hover {
        cursor: pointer;
    }
    p {
        margin-bottom: 0;
        &:first-of-type {
        font-weight: 500;
        font-size: 1rem;
        color: #263238;
        span {
            color: gray;
        }
        }
        &:last-of-type {
        font-size: 0.8rem;
        }
    }
    }
